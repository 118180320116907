/**
 * @copyright 2020 @ DigiNet
 * @author THIEN
 * @create 6/24/2020
 * @Example
 */
import React, {Component} from "react";
import Config from "../../../../config";

import GridContainer from "../../../grid-container/grid-container";
import {connect} from "react-redux";
import {bindActionCreators, compose} from "redux";
import {FormGroup, Col, Row} from "react-bootstrap";
import _ from "lodash";
import {Column} from "devextreme-react/data-grid";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import Filter from "../../../filter/filter";
import Modal from "../../../common/modal/modal";
import withStyles from "@material-ui/core/styles/withStyles";

import * as W29F2010Actions from "../../../../redux/W2X/W29F2010/W29F2010_actions";
import * as generalActions from "../../../../redux/general/general_actions";
import W29F2012 from "../W29F2012/W29F2012";

import UserImage from "../../../common/user/user-image";
import UserName from "../../../common/user/user-name";
import GridActionBar from "../../../grid-container/grid-actionbar";
import W84F3005 from "../../../W8X/W84/W84F3005/W84F3005"
import W29F2045 from "./../W29F2045/W29F2045"
import W29F2018 from '../W29F2018/W29F2018';
import {Tooltip, ButtonIcon, Typography, DatePicker, Dropdown, Button} from 'diginet-core-ui/components';
import Status from "../../../common/status/status";

const styles = {
    root: {
        marginBottom: 15,
        borderRadius: 0,
        boxShadow: "none",
        backgroundColor: "#F3F3F3",
    },
    labelCheckbox: {
        fontSize: "0.8rem",
    },
    checkbox: {
        padding: "0 4px",
    },
    tab: {
        maxWidth: "fit-content",
        padding: "15px 58px",
    },
    chip: {
        fontSize: "1rem",
        fontWeight: 400,
        border: "none",
        backgroundColor: "#EBEBEB",
    },
    tabActive: {
        backgroundColor: "#fff",
    },
    btnFilter: {
        backgroundColor: "#9999993d",
        textTransform: "uppercase",
    },
    avatarEmployees: {
        display: "flex",
        alignItems: "center",
        "& .user-picture-image": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: 28,
            minWidth: 28,
        },
    },
    hoverAction: {
        minHeight: "53px !important",
    },
    styleIcon: {
        padding: 0,
        width: 30,
        height: 30,
        marginRight: 3
    }
};

class W29F2010 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            gridLoading: false,
            dataFormGrid: [],
            DateFrom: null,
            DateTo: null,
            AppStatusID: "",
            mode: "add",
            rowData: null,
            showW09F2012: false,
            isUseOTType: 0,
            isUseActOTHour: 0,
            disabled: false,
            showW84F3005Popup: false,
            w84F3005PopupData: {
                TransID: null
            },
            showW29F2045Popup: false,
            w29F2045PopupMode: "add",
            w29F2045PopupData: {
                TransID: null
            },
            isEdit: false,
            isDisabled: false,
        };
        this.defaultParams = {
            FormID: "W29F2010",
            Language: Config.language || "84",
        };
        this.paramFilters = {
            DepartmentID: "",
            EmployeeID: "",
            FieldSort: "",
            TypeSort: "",
            Mode: 0,
            skip: 0,
            limit: 10,
        };
        this.W29F2018Ref = null;
    }

    loadFormGrid = (VoucherID) => {
        const {voucher_id} = Config.getUrlParams(this.props);
        VoucherID = VoucherID ? VoucherID : voucher_id;
        const {DateFrom, DateTo, AppStatusID} = this.state;
        const params = {
            DateFrom,
            DateTo,
            AppStatusID,
            ...this.defaultParams,
            ...this.paramFilters,
            TransID: VoucherID || ""
        };
        this.setState({gridLoading: true, disabled: true});
        this.props.w29f2010Actions.getFormGrid(params, (err) => {
            if (err) Config.popup.show("INFO", _.get(err, "message", Config.lang("Loi_chua_xac_dinh")));
            this.setState({gridLoading: false, disabled: false});
        });
    };

    loadCboAppStatus = () => {
        this.setState({cbLoading: true});
        this.props.generalActions.getCboAppStatus({...this.defaultParams, FormID: "STANDARD"}, (err) => {
            if (err) Config.popup.show("ERROR", err);
            this.setState({cbLoading: false});
        });
    };

    onHistoryApproval = (e, data) => {
        this.setState({w84F3005PopupData: {TransID: data ? data.TransID : null}, showW84F3005Popup: true})
    };

    onAddActOTHour = (data) => {
        let w29F2045PopupData = data ? {...data, LinkOTTransID: data.TransID} : {}
        this.setState({showW29F2045Popup: true, w29F2045PopupMode: "add", w29F2045PopupData})
    };

    checkOTType = () => {
        this.props.w29f2010Actions.loadCheckUseOTType((err, data) => {
            if (err) return Config.popup.show("ERROR", err);
            this.setState({
                isUseActOTHour: _.get(data, "IsUseActOTHour", 0),
                isUseOTType: _.get(data, "IsUseOTType", 0)
            });
        })
    };

    renderStatus = (e) => {
        const {data} = e.row;
        return <Status data={data}/>;
    };

    renderEmpProfile = (e) => {
        const {data} = e.row;
        const employeeId = _.get(data, "EmployeeID", "");
        return (
            <div style={{display: "flex", alignItems: "center"}}>
                <UserImage width={38} height={38} data={data}/>
                <Typography type="p2">{employeeId}</Typography>
            </div>
        );
    };

    renderEmpName = (e) => {
        const {data} = e.row;
        return <UserName data={data}/>
    };

    checkIsEdit = (appStatusID = 0) => {
        this.setState({isEdit: true, isDisabled: !!appStatusID});
    };

    renderButtonAction = (e) => {
        const allowClick = Boolean(_.get(e, "data.AppStatusID", 1));
        const {IsAddActOTHour, IsAllowCancel, IsCancel, AppStatusID} = e?.data || {};
        const arrIcon = [
            {
                iconName: "history",
                title: Config.lang('Lich_su_duyet'),
                disabled: false,
                fn: () => this.onHistoryApproval(null, e && e.data),
            },
            {
                iconName: "edit",
                title: Config.lang('Sua'),
                disabled: allowClick,
                fn: (evt) => {
                    if (IsCancel) {
                        this.checkIsEdit(AppStatusID ?? 0);
                        this.onHandleOpenW29F2018(evt, e?.data ?? {});
                    } else {
                        this.onEdit(e);
                    }
                }
            },
            {
                iconName: "delete",
                title: Config.lang("Xoa"),
                disabled: allowClick,
                fn: () => {
                    Config.popup.show("YES_NO", Config.lang("Ban_co_chac_chan_muon_xoa_du_lieu_nay"), () => {
                        this.onDeleteData(e);
                    });
                },
            },
        ];
        return (
            <GridActionBar>
                <div className={"display_row"}>
                    {arrIcon.map(({iconName, title, disabled, fn}, i) =>
                        <Tooltip key={i} title={title}>
                            <ButtonIcon
                                name={iconName}
                                disabled={disabled}
                                onClick={fn}
                                circular
                                viewType="text"
                            />
                        </Tooltip>
                    )}
                    {
                        IsAddActOTHour === 1 &&
                        <Tooltip title={Config.lang('Dang_ky_gio_tang_ca_thuc_te')}>
                            <ButtonIcon
                                circular
                                viewType="text"
                                name={"AddCircle"}
                                onClick={() => this.onAddActOTHour(e && e.data)}
                            />
                        </Tooltip>
                    }
                    {
                        IsAllowCancel === 1 &&
                        <Tooltip title={Config.lang('Huy_dang_ky_tang_ca')}>
                            <ButtonIcon
                                circular
                                viewType="text"
                                name={"Close"}
                                onClick={(evt) => this.onHandleOpenW29F2018(evt, e?.data ?? {})}
                            />
                        </Tooltip>
                    }
                </div>
            </GridActionBar>
        );
    };


    // renderButtonAction = (e) => {
    //     const allowClick = Boolean(_.get(e, "data.AppStatusID", 1));
    //     const {IsAddActOTHour,}  =e&&e.data?e.data:{}
    //     return (
    //         <GridActionBar>
    //             <div className="">
    //             <IconButton
    //
    //                     aria-label="history"
    //                     style={{ padding: 0, width: 30, height: 30, marginRight: 3 }}
    //                     onClick={() => this.onHistoryApproval(null,e&&e.data)}
    //                 > <Tooltip title={Config.lang('DHR_Lich_su_duyet')}>
    //                     <HistoryIcon/>
    //                     </Tooltip>
    //                 </IconButton>
    //                 <IconButton
    //                     disabled={allowClick}
    //                     aria-label="delete"
    //                     style={{ padding: 0, width: 30, height: 30, marginRight: 3 }}
    //                     onClick={() => this.onEdit(e)}
    //                 >
    //                      <Tooltip title={Config.lang('DHR_Sua')}><div>
    //                     <Icons name={"edit"}/>
    //                     </div></Tooltip>
    //                 </IconButton>
    //                 <IconButton
    //                     disabled={allowClick}
    //                     aria-label="delete"
    //                     style={{ padding: 0, width: 30, height: 30, marginRight: 3 }}
    //                     onClick={() => {
    //                         Config.popup.show("YES_NO", Config.lang("DHR_Ban_co_chac_chan_muon_xoa_du_lieu_nay"), () => {
    //                             this.onDeleteData(e);
    //                         });
    //                     }}
    //                 >
    //                      <Tooltip title={Config.lang('DHR_Xoa')}><div>
    //                     <Icons name={"delete"}/>
    //                     </div></Tooltip>
    //                 </IconButton>
    //                 {
    //                     IsAddActOTHour===1&&
    //                     <IconButton
    //                         aria-label={Config.lang('DHR_Dang_ky_gio_tang_ca_thuc_te')}
    //                         style={{ padding: 0, width: 30, height: 30, marginRight: 3 }}
    //                         onClick={()=>this.onAddActOTHour(e&&e.data)}
    //                     ><Tooltip title={Config.lang('DHR_Dang_ky_gio_tang_ca_thuc_te')}><div>
    //                         <Icons name={"circle-plus"}/>
    //                         </div></Tooltip>
    //                     </IconButton>
    //
    //                 }
    //             </div>
    //         </GridActionBar>
    //     );
    // };


    onHandleOpenW29F2018 = (e, data = {}) => {
        if (this.W29F2018Ref) {
            const currentTarget = e?.currentTarget ?? null;
            this.W29F2018Ref.onOpen(currentTarget, data);
        }
    };


    renderFilters = () => {
        const {DateFrom, DateTo, AppStatusID} = this.state;
        const {getCboAppStatus} = this.props;
        return (
            <Filter
                isUseDDCore
                placeholder={Config.lang("Noi_dung_can_tim_kiem")}
                onOpenLoaded={this.onLoadDataFilter}
                renderFilter={() => {
                    return (
                        <>
                            <FormGroup>
                                <Row>
                                    <Col xs={12} sm={6}>
                                        <DatePicker
                                            viewType="outlined"
                                            label={Config.lang("Tu")}
                                            displayFormat={"DD/MM/YYYY"}
                                            placeholder={"DD/MM/YYYY"}
                                            max={DateTo}
                                            clearAble
                                            value={DateFrom}
                                            onChange={(e) => this.handleFilterChange("DateFrom", e)}
                                        />
                                    </Col>
                                    <Col xs={12} sm={6}>
                                        <DatePicker
                                            viewType="outlined"
                                            label={Config.lang("Den")}
                                            min={DateFrom}
                                            displayFormat={"DD/MM/YYYY"}
                                            placeholder={"DD/MM/YYYY"}
                                            clearAble
                                            value={DateTo}
                                            onChange={(e) => this.handleFilterChange("DateTo", e)}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <Dropdown
                                            clearAble
                                            viewType={"outlined"}
                                            valueExpr={"AppStatusID"}
                                            displayExpr={"AppStatusName"}
                                            placeholder={Config.lang("Chon")}
                                            label={Config.lang("Trang_thai_duyet")}
                                            onChange={e => this.handleFilterChange("AppStatusID", e)}
                                            dataSource={getCboAppStatus}
                                            value={AppStatusID || ""}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup className={"display_row align-center valign-middle"}>
                                <Button startIcon={'Search'}
                                        color={'primary'}
                                        label={Config.lang("Tim_kiem")}
                                        viewType={'outlined'}
                                        onClick={() => this.loadFormGrid()}
                                />
                            </FormGroup>
                        </>
                    );
                }}
            />
        );
    };

    handleFilterChange = (key, data) => {
        const stateFieldName = ["DateTo", "DateFrom", "AppStatusID"];
        const value = _.get(data, "value", "");
        if (stateFieldName.includes(key)) {
            this.setState({
                [key]: value,
            });
            return;
        }
        this.paramFilters[key] = value;
    };

    onDeleteData = (e) => {
        const LinkOTTransID = _.get(e, "data.LinkOTTransID", "")
        const params = {
            TransID: _.get(e, "data.TransID", ""),
        };
        const _deleteCb = (err, res) => {
            if (err) {
                Config.popup.show("ERROR", err);
                return;
            }
            if (!_.get(res, "status", 1)) {
                Config.notify.show("success", Config.lang("Xoa_thanh_cong"), 2000);
            } else {
                Config.popup.show("INFO", _.get(res, "message", Config.lang("Loi_chua_xac_dinh")));
            }
            this.loadFormGrid()
        }
        if (LinkOTTransID === "") {
            this.props.w29f2010Actions.deleteData(params, _deleteCb);
        } else {
            this.props.w29f2010Actions.deleteActOTHour(params, _deleteCb);
        }

    };

    onLoadDataFilter = () => {
        this.loadCboAppStatus();
    };

    onChangePage = (page) => {
        this.paramFilters.skip = page * this.paramFilters.limit;
        this.loadFormGrid();
    };

    onChangePerPage = (perPage) => {
        this.paramFilters.skip = 0;
        this.paramFilters.limit = perPage;
        this.loadFormGrid();
    };

    onOrderChanged = (e) => {
        if (!e || !e.column) return false;
        this.paramFilters.FieldSort = e.column.dataField;
        this.paramFilters.TypeSort = e.typeSort;
        this.loadFormGrid();
    };

    onAdd = () => {
        this.setState({
            mode: "add",
            rowData: [],
            showW09F2012: true,
        });
    };

    onView = (e) => {
        const {data} = e.row;
        if (data && data.LinkOTTransID) {
            this.setState({
                w29F2045PopupMode: "view",
                w29F2045PopupData: data,
                showW29F2045Popup: true,
            });
            return
        }
        if (data?.IsCancel) {
            this.checkIsEdit(data?.AppStatusID ?? 0);
            this.onHandleOpenW29F2018(e?.event, data ?? {});
        } else {
            this.setState({
                mode: "view",
                rowData: data,
                showW09F2012: true,
            });
        }

    };

    onEdit = (e, isNotify) => {
        const {data} = e.row;
        if (data && data.LinkOTTransID) {
            this.setState({
                w29F2045PopupMode: "edit",
                w29F2045PopupData: data,
                showW29F2045Popup: true,
            });
            return
        }
        this.setState({
            mode: isNotify ? "view" : "edit",
            rowData: data,
            showW09F2012: true,
        });
    };

    onCloseModal = (isReload = false, showPopup = false) => {
        isReload && this.loadFormGrid();
        if (isReload) {
            this.setState({
                showW09F2012: false,
            });
        }
        if (!showPopup) {
            this.setState({
                showW09F2012: false,
            });
        }

    };

    onCloseW29F2045Popup = (isReload) => {
        isReload && this.loadFormGrid();
        this.setState({
            showW29F2045Popup: false,
            w29F2045PopupData: {}
        });
    };

    onCloseW29F2018 = (isReload = false) => {
        isReload && this.loadFormGrid();
        this.setState({isEdit: false, isDisabled: false});
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {getFormGrid} = this.props;
        Config.callChildForm({
            FormID: "W29F2010",
            // ID: voucher_id,
            keyExpr: "TransID",
            data: getFormGrid && getFormGrid.rows ? getFormGrid.rows : [],
            onLoad: (params) => this.loadFormGrid(params && params.ID),
            onAction: (e, data) => {
                if (Boolean(_.get(data, "AppStatusID", 1))) {
                    this.onView({row: {data: data}});
                } else {
                    this.onEdit({row: {data: data}});
                }
            }
        }, this.props);
    }

    getInfo = (props) => {
        if (!props) return {};
        const {location} = props ? props : {};
        const url = new window.URLSearchParams(window.location.search);
        if (url && url.get("voucher_id")) {
            return {ProTransID: url.get("voucher_id")};
        } else if (location && location.state) {
            return {
                ProTransID: location.state.voucher_id,
            };
        } else {
            return {};
        }
    };

    render() {
        const {skip, limit} = this.paramFilters;
        const {
            selectedRowKeys, gridLoading, disabled, isUseOTType, isUseActOTHour, rowData, mode, showW09F2012,
            showW84F3005Popup, w84F3005PopupData = {}, showW29F2045Popup,
            w29F2045PopupMode, w29F2045PopupData, isEdit, isDisabled
        } = this.state;
        const {getFormGrid} = this.props;
        return (
            <FormGroup>
                <W84F3005 open={showW84F3005Popup} onClose={() => this.setState({showW84F3005Popup: false})}
                          FormID="W29F2010" TransID={w84F3005PopupData.TransID}/>
                <W29F2045 disabledCboEmployee open={showW29F2045Popup} mode={w29F2045PopupMode}
                          onClose={this.onCloseW29F2045Popup} FormID="W29F2010" data={w29F2045PopupData}/>
                <W29F2018 ref={ref => this.W29F2018Ref = ref} isDisabled={isDisabled} isEdit={isEdit}
                          onClose={(isReload) => this.onCloseW29F2018(isReload)}/>
                <ActionToolbar title={Config.lang("Tang_ca")}>
                    <Button
                        size={"medium"}
                        color={"primary"}
                        viewType={"filled"}
                        label={Config.lang("Them")}
                        startIcon={"AddCircle"}
                        disabled={disabled}
                        onClick={this.onAdd}
                    />
                </ActionToolbar>
                <div className={"hidden"}>{this.renderFilters()}</div>
                <Modal
                    open={showW09F2012}
                    maxWidth={"md"}
                    fullWidth={true}
                    onClose={() => this.onCloseModal()}
                    title={Config.lang("Dang_ky_tang_ca")}
                >
                    <W29F2012 mode={mode} data={rowData}
                              onClose={(isReload, showPopup) => this.onCloseModal(isReload, showPopup)}/>
                </Modal>
                <FormGroup>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} className="gird-w29f2005">
                            <GridContainer
                                itemPerPage={limit}
                                skipPerPage={skip}
                                selection={{
                                    mode: "single",
                                }}
                                height={Config.getHeightGrid()}
                                onDbCellClick={this.onView}
                                loading={gridLoading}
                                selectedRowKey={selectedRowKeys}
                                style={{border: "none"}}
                                dataSource={_.get(getFormGrid, "rows", [])}
                                keyExpr={"TransID"}
                                showBorders={false}
                                typePaging={"remote"}
                                totalItems={_.get(getFormGrid, "total", 0)}
                                showColumnLines={false}
                                hoverStateEnabled={true}
                                onChangePage={this.onChangePage}
                                onChangePerPage={this.onChangePerPage}
                                onOrderChanged={this.onOrderChanged}
                            >
                                <Column
                                    alignment={"right"}
                                    allowEditing={false}
                                    cellRender={this.renderButtonAction}
                                    allowSorting={false}
                                    visible={Config.isMobile}
                                />
                                <Column
                                    width={220}
                                    caption={Config.lang("Ma_nhan_vien")}
                                    dataField={"EmployeeID"}
                                    cellRender={this.renderEmpProfile}
                                    alignment={"left"}
                                    allowSorting={true}
                                />
                                <Column
                                    width={220}
                                    caption={Config.lang("Ten_nhan_vien")}
                                    dataField={"EmployeeName"}
                                    cellRender={this.renderEmpName}
                                    alignment={"left"}
                                    allowSorting={true}
                                />
                                <Column
                                    caption={Config.lang("Ngay_tang_ca")}
                                    dataField={"AttendanceDate"}
                                    alignment={"center"}
                                    dataType="date"
                                />
                                <Column caption={Config.lang("Tang_ca_tu")} dataField={"OTFrom"} alignment={"center"}/>
                                <Column caption={Config.lang("Tang_ca_den")} dataField={"OTTo"} alignment={"center"}/>
                                <Column caption={Config.lang("So_gio")} dataField={"OTHour"} alignment={"center"}/>
                                <Column visible={!!isUseActOTHour}
                                        caption={Config.lang("Gio_tang_ca_thuc_te")}
                                        dataField={"ActualOT"}
                                        alignment={"center"}/>
                                <Column visible={!!isUseOTType}
                                        caption={Config.lang("Loai_tach_tang_ca")}
                                        dataField={"OTTypeName"}
                                        alignment={"center"}
                                />
                                <Column caption={Config.lang("Loai_ngay")} dataField={"WorkDayTypeName"}
                                        alignment={"center"}/>
                                <Column caption={Config.lang("Ca_vao_ra")} dataField={"ShiftInOut"}
                                        alignment={"center"}/>
                                <Column caption={Config.lang("Loai_phieu")} dataField={"VoucherType"}/>
                                <Column
                                    alignment={"center"}
                                    dataField={"AppStatusID"}
                                    caption={""}
                                    cellRender={this.renderStatus}
                                    minWidth={150}
                                    maxWidth={200}
                                />
                                <Column
                                    alignment={"center"}
                                    fixed={true}
                                    fixedPosition={"right"}
                                    allowSorting={false}
                                    visible={!Config.isMobile}
                                    cellRender={this.renderButtonAction}
                                />
                            </GridContainer>
                        </Col>
                    </Row>
                </FormGroup>
            </FormGroup>
        );
    }

    componentDidMount = () => {
        this.checkOTType();
        this.loadFormGrid();
    };
}

export default compose(
    connect(
        (state) => ({
            getFormGrid: state.W29F2010.getFormGrid,
            getCboAppStatus: state.general.getCboAppStatus,
        }),
        (dispatch) => ({
            generalActions: bindActionCreators(generalActions, dispatch),
            w29f2010Actions: bindActionCreators(W29F2010Actions, dispatch),
        }),
    ),
    withStyles(styles),
)(W29F2010);
