/**
 * @Copyright 2021 @DigiNet
 * @Author XUANLOC
 * @Create 6/8/2021
 * @Example
 */

import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    popoverAction: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        width: '600px',
        padding: 15,
        [theme.breakpoints.down('xs')]: {
            width: '300px',
        },
    },
}));
