/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 2/24/2021
 * @Example
 */
import React from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import * as W29F2016Actions from "../../../../redux/W2X/W29F2016/W29F2016_actions";
import * as generalActions from "../../../../redux/general/general_actions";
import {bindActionCreators, compose} from "redux";
import Config from "../../../../config";
import {Row, Col, FormGroup} from "react-bootstrap"
import ButtonGeneral from "../../../common/button/button-general";
import withStyles from "@material-ui/core/styles/withStyles";
import {TextField, Checkbox} from "../../../common/form-material";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import {Column} from "devextreme-react/data-grid";
import GridContainer from "../../../grid-container/grid-container";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from '@material-ui/icons/Search';
import DropDownOrganization from "../../../common/dropdown/dropdown-treeview";
import DateBoxPicker from "../../../common/form-material/date-box";
import Modal from "../../../common/modal/modal";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Divider from "@material-ui/core/Divider";
import InlineSVG from "react-inlinesvg";
import Icons from "../../../common/icons";
import moment from "moment";
const styles = (theme) => ({
    panel: {
        transition: 'all .4s'
    },
    panelForm: {
        transition: 'all .5s',
        opacity: 1
    },
    hiddenOpacity: {
        opacity: 0
    },
    leftPanelMinimum: {
        width: '74px',
    },
    rightPanelMinimum: {
        width: 'calc(100% - 74px)'
    },
    titleColorFilter: {
        backgroundColor: theme.palette.primary.main + ' !important',
        '& svg': {
            '& path': {
                fill: theme.palette.primary.contrastText
            }
        }
    }
});
class W29F2016 extends React.PureComponent {
    constructor(props) {
        super(props);
        const filters = this.getDataFilter(props.dataDefaults);
        this.state = {
            isSaving: false,
            dataGrid: [],
            totalDataGrid: 0,
            minimum: false,
            loadingDataGrid: false,
            AttendanceDateFrom: null,
            AttendanceDateTo: null,
            PreUsingPeriodFrom: null,
            PreUsingPeriodTo: null,

            selectedRowKeys: [],
            isShowSelected: false,
            dataUseOtType: {},
            dataGridSetting: {},
        };
        this.isSelecting = false;
        this.tmpSelectedRowKeys = [];
        this.tmpCurrentSelectedRowKeys = [];
        this.tmpSelectedRowData = [];
        this.tmpDataSelectedRowKeys = [];
        // this.tmpDataSaveSelectedEmployee = [];
        this.chooseOnSelect = false;
        this.selectAll = false;
        this.changePage = false;
        this.isChangeShow = false;
        this.filter = {
            limit: 15,
            skip: 0,
            ...filters
        };
        this.dataGrid = null;
    }

    setLoading = (key, status = false) => {
        if (!key) return;
        this.setState({['loading' + key]: status});
    };

    loadUseOtType = () => {
        this.props.w29f2016Actions.getUseOtType((errors, data) => {
            if (errors) {
                Config.popup.show('ERROR', errors);
                return false;
            }
            this.setState({
                dataUseOtType: data
            });
        });
    };

    loadGridSetting = () => {
        this.props.w29f2016Actions.getGridSetting((errors, data) => {
            if (errors) {
                Config.popup.show('ERROR', errors);
                return false;
            }
            this.setState({
                dataGridSetting: data
            });
        });
    };


    componentDidUpdate(prevProps, prevState, snapshot) {
        const {dataDefaults, open} = this.props;
        if(prevProps.open !== open && open) {
            this.loadGrid();
        }
        const arrayKeys = (dataDefaults && dataDefaults.dataSelected) ? dataDefaults.dataSelected.map(d => d.TransID) : [];
        if(JSON.stringify(prevProps.dataDefaults) !== JSON.stringify(this.props.dataDefaults)
            || (JSON.stringify(arrayKeys) !== JSON.stringify(this.tmpSelectedRowKeys) && prevProps.open !== open && open)
        ) {
            const dataDefaults = this.props.dataDefaults;
            this.filter = this.getDataFilter(dataDefaults);
            if (dataDefaults && dataDefaults.selectedRowKeys) {
                this.tmpSelectedRowKeys = [];
                dataDefaults.selectedRowKeys.forEach(select => {
                    if (this.tmpSelectedRowKeys.indexOf(select) < 0) {
                        this.tmpSelectedRowKeys.push(select);
                    }
                });
            }
            if (dataDefaults && dataDefaults.dataSelected) {
                this.tmpSelectedRowData = [];
                dataDefaults.dataSelected.forEach(select => {
                    if (this.tmpSelectedRowData.indexOf(select) < 0) {
                        this.tmpSelectedRowData.push(select);
                    }
                });
            }
            if (dataDefaults && dataDefaults.isLoaded) {
                this.loadGrid(true);
            }
        }
    }

    componentDidMount() {
        const {dataDefaults} = this.props;
        this.loadUseOtType();
        this.loadGrid();
        this.loadGridSetting();
        if (dataDefaults) {
            if (dataDefaults.selectedRowKeys && dataDefaults.selectedRowKeys.length > 0) {
                dataDefaults.selectedRowKeys.forEach(select => {
                    if (this.tmpSelectedRowKeys.indexOf(select) < 0) {
                        this.tmpSelectedRowKeys.push(select);
                    }
                });
            }
            if (dataDefaults && dataDefaults.dataSelected) {
                this.tmpSelectedRowData = [];
                dataDefaults.dataSelected.forEach(select => {
                    if (this.tmpSelectedRowData.indexOf(select) < 0) {
                        this.tmpSelectedRowData.push(select);
                    }
                });
            }
            if (dataDefaults.isLoaded) {
                this.loadGrid(true);
            }
        }
    }

    getDataFilter = (dataDefaults) => {
        return {
            ...this.filter,
            strSearch: dataDefaults?.strSearch || "",
            OrgChartID: dataDefaults?.OrgChartID && dataDefaults.OrgChartID.length > 0 ? dataDefaults.OrgChartID : [],
            AttendanceDateFrom: dataDefaults?.AttendanceDateFrom || null,
            AttendanceDateTo: dataDefaults?.AttendanceDateTo || null,
        };
    };

    handleChange = (key, e) => {
        if (!key) return false;
        switch (key) {
            case "Search":
                this.filter.strSearch = e.target.value;
                break;
            case "OrgChartID":
                this.filter.OrgChartID = e;
                break;
            case "AttendanceDateFrom":
                this.setState({AttendanceDateFrom: e.value});
                this.filter.AttendanceDateFrom = e.value;
                break;
            case "AttendanceDateTo":
                this.setState({AttendanceDateTo: e.value});
                this.filter.AttendanceDateTo = e.value;
                break;
            default:
                break;
        }
    };

    loadGrid = (isReset) => {
        const {limit, skip, strSearch, OrgChartID, AttendanceDateFrom, AttendanceDateTo
              } = this.filter;
        const params = {
            Language: Config.language || "84",
            Employee: strSearch,
            OrgchartID: OrgChartID && OrgChartID.length > 0 ? JSON.stringify(OrgChartID) : JSON.stringify([]),
            AttendanceDateFrom: AttendanceDateFrom,
            AttendanceDateTo: AttendanceDateTo,
            FormID: "W29F2016",
            limit: limit,
            skip: skip
        };
        if (!isReset) {
            this.changePage = true;
        }
        this.setState({loadingDataGrid: true});
        this.props.w29f2016Actions.getForm(params, (errors, data) => {
        this.setState({loadingDataGrid: false});
            if (errors) {
                Config.popup.show('ERROR', errors);
                return false;
            }
            let selectedRowKeys = [];
            if (data.rows && data.rows.length > 0) {
                data.rows.forEach((e) => {
                    if (this.tmpSelectedRowKeys.indexOf(e.TransID) > -1) {
                        selectedRowKeys.push(e.TransID);
                    }
                });
            }
            this.setState({
                dataGrid: data.rows ? data.rows : data,
                totalDataGrid: data.total,
                selectedRowKeys: selectedRowKeys
            });
        });
    };

    onFilter = () => {
        this.filter.skip = 0;
        this.loadGrid(true);
    };

    onChangePage = (page) => {
        this.filter.skip = page * this.filter.limit;
        this.loadGrid();
    };

    onChangePerPage = (per) => {
        this.filter.skip = 0;
        this.filter.limit = per;
        this.loadGrid();
    };

    onSelect = () => {
        let selectedRowKeys = this.tmpSelectedRowKeys;
        if (this.selectAll) {
            //chạy api để lấy tất cả....
            //rồi mới insert được
        }
        this.tmpDataSelectedRowKeys = [];
        const {FormID, onChosen} = this.props;
        if (selectedRowKeys && selectedRowKeys.length > 0) {
            this.setState({isSaving: true});
            const params = {
                EmployeeID: JSON.stringify(selectedRowKeys),
                HostID: "",
                FormID: FormID ? FormID : ""
            };
            this.props.w09f6000Actions.saveSelectedEmployee(params, (errors) => {
                this.setState({isSaving: false});
                if (errors) {
                    errors.customMessage = {
                        "F6000E001": "EmployeeID " + Config.lang("Bat_buoc")
                    }
                    Config.popup.show('ERROR', errors);
                    return false;
                }
                if (onChosen) onChosen(this.tmpSelectedRowKeys);
            });
        } else {
            Config.popup.show("INFO", Config.lang("Ban_chua_chon_nhan_vien"));
            return false;
        }
    };

    onApproval = (mode) => {
        let selectedRowData = this.tmpSelectedRowData;
        if (selectedRowData && selectedRowData.length > 0) {
            selectedRowData.forEach(val => {
                val.IsUsed = 1;
                val.AttendanceDate = val.AttendanceDate ? moment(val.AttendanceDate).format("YYYY-MM-DD") : null;
            });
            const params = {
                Mode: mode,
                data: selectedRowData ? JSON.stringify(selectedRowData) : ""
            };
            let message = mode === 0 ?
                Config.lang("Ban_co_chac_chan_muon_duyet") :
                Config.lang("Ban_co_chac_chan_muon_tu_choi");
            Config.popup.show("YES_NO", message, () => {
                (async () => {
                    this.setState({ isSaving: true });
                    await this.props.w29f2016Actions.approval(params, (errors, data) => {
                        this.setState({ isSaving: false });
                        if (errors) {
                            Config.popup.show("ERROR", errors);
                            return false;
                        }
                        if (data) {
                            if (data.Status === 0) {
                                if (mode === 0) {
                                    Config.notify.show("success", Config.lang("Duyet_thanh_cong"), 2000);
                                    this.onClose(true);
                                    return false;
                                }
                                if (mode === 1) {
                                    Config.notify.show("success", Config.lang("Tu_choi_thanh_cong"), 2000);
                                    this.onClose(true);
                                    return false;
                                }
                            }
                            else if (data.Status === 1) {
                                if(data?.MsgAsk === 0){
                                    let message = data.Message;
                                    Config.popup.show('INFO', message);
                                }
                                else if(data?.MsgAsk === 1){
                                    Config.popup.show('YES_NO', data.Message, () => {
                                        this._commitSave(data.code); // Yes
                                    });
                                }
                            }
                        }
                    });
                })();
            });
        } else {
            Config.popup.show("INFO", Config.lang("Ban_chua_chon_nhan_vien"));
            return false;
        }
    };

    _commitSave = (code) => {
        this.props.w29f2016Actions.confirmApproval({code},(err, data) =>{
            if(err) Config.popup.show('ERROR', err);
            else if(data){
                Config.popup.show('INFO', Config.lang('Duyet_thanh_cong'));
            }
        })
    };

    setSelectedEmployees = (e) => {
        const currentSelectedRowKeys = e.currentSelectedRowKeys;
        const currentSelectedRowData = e.selectedRowsData;
        const currentDeselectedRowKeys = e.currentDeselectedRowKeys;
        if (currentDeselectedRowKeys.length > 0) {
            if (this.changePage) { this.changePage = false; return false; }
            if (this.isChangeShow) { this.isChangeShow = false; return false; }

            this.tmpSelectedRowData = this.tmpSelectedRowData.filter((e) => {
                return currentDeselectedRowKeys.indexOf(e.TransID) < 0;
            });
            this.tmpSelectedRowKeys = this.tmpSelectedRowKeys.filter((e) => {
                return currentDeselectedRowKeys.indexOf(e) < 0;
            });
            this.tmpDataSelectedRowKeys = this.tmpDataSelectedRowKeys.filter((e) => {
                return currentDeselectedRowKeys.indexOf(e) < 0;
            });
        } else if (currentSelectedRowKeys.length > 0) {
            currentSelectedRowKeys.forEach((val) => {
                if (this.tmpSelectedRowKeys.indexOf(val) < 0) {
                    this.tmpSelectedRowKeys.push(val);
                    const data = currentSelectedRowData.find(d => d.TransID === val);
                    if (data) this.tmpSelectedRowData.push(data);
                }

                if (this.tmpDataSelectedRowKeys.indexOf(val) < 0) {
                    this.tmpDataSelectedRowKeys.push(val);
                }
            });
        }
    };

    onSelectionChanged = async (e) => {
        if (!this.isSelecting) {
            this.isSelecting = true;
            await this.setSelectedEmployees(e);
            this.setState({
                selectedRowKeys: e.selectedRowKeys
            }, () => {
                this.isSelecting = false;
                this.changePage = false;
                this.isChangeShow = false;
            });
        }
    };

    collapsePanel = (flag) => {
        if (typeof flag !== "undefined") {
            this.setState({minimum: !!flag});
        } else {
            this.setState({minimum: !this.state.minimum});
        }
    };

    changeTypeShow = (e) => {
        if (!e) return false;
        this.isChangeShow = true;
        this.setState({isShowSelected: e.target.checked});
    };

    // getTotalSelectedRowKey = () => {
    //     return `(${this.tmpSelectedRowKeys.length})`;
    // };

    onClose = (isSaved = false) => {
        const { onClose, dataDefaults } = this.props;
        if(!this.chooseOnSelect) {
            if (dataDefaults && dataDefaults.selectedRowKeys) {
                this.tmpSelectedRowKeys = [];
                dataDefaults.selectedRowKeys.forEach(select => {
                    if (this.tmpSelectedRowKeys.indexOf(select) < 0) {
                        this.tmpSelectedRowKeys.push(select);
                    }
                });
            }
            if (dataDefaults && dataDefaults.dataSelected) {
                this.tmpSelectedRowData = [];
                dataDefaults.dataSelected.forEach(select => {
                    if (this.tmpSelectedRowData.indexOf(select) < 0) {
                        this.tmpSelectedRowData.push(select);
                    }
                });
            }
            this.setState({selectedRowKeys: this.tmpSelectedRowKeys});
        }
        this.tmpSelectedRowKeys = [];
        this.tmpSelectedRowData = [];
        this.setState({selectedRowKeys: []});
        if(isSaved) {
            this.loadGrid();
        }
        if(onClose) onClose(isSaved);
    };

    //true: Con key chua duoc select; false: da select het..
    _checkSelectedRowsKeys = (dataSource) => {
        let isCheck = false;
        if (dataSource && dataSource.length > 0 && this.tmpSelectedRowKeys.length > 0) {
            const dataKeys = dataSource.map(d => d.TransID);
            for (let key of this.tmpSelectedRowKeys) {
                if (!dataKeys.includes(key)) {
                    isCheck = true; break;
                }
            }
        }
        return isCheck;
    };

    render() {
        const {classes, open} = this.props;
        const {loading, isSaving, dataGrid, totalDataGrid,
                  selectedRowKeys, minimum, AttendanceDateFrom, AttendanceDateTo, isShowSelected,
                  dataUseOtType, loadingDataGrid, dataGridSetting
              } = this.state;
        const {
                  OrgChartID,
                  strSearch
              } = this.filter;
        let _dataGrid = dataGrid;
        let _selectedRowKeys = selectedRowKeys;
        if (isShowSelected) {
            this.tmpCurrentSelectedRowKeys = selectedRowKeys;
            if(this.tmpSelectedRowData) {
                _dataGrid = this.tmpSelectedRowData;
                if (_selectedRowKeys.length === this.tmpSelectedRowKeys.length) this.isChangeShow = false;
                _selectedRowKeys = this.tmpSelectedRowKeys;
            }

        } else if (this.isChangeShow) {
            if (!this._checkSelectedRowsKeys(_dataGrid)) this.isChangeShow = false;
            // if (_selectedRowKeys.length === this.tmpSelectedRowKeys.length) this.isChangeShow = false;
        }
        return (
            <React.Fragment>
                <Modal open={open} maxWidth={"lg"} fullWidth={true}>
                    <Modal.Title disableTypography>
                        <ActionToolbar
                            alignment={"flex-end"}
                            title={Config.lang("Duyet_tang_ca_hang_loat")}
                            showBorder={false}
                            upperCase={false}
                            className={"text-uppercase"}
                            style={{
                                marginTop: 0,
                            }}
                        >
                            <ButtonGeneral name={Config.lang('Dong1')}
                                           typeButton={'cancel'}
                                           disabled={isSaving || loadingDataGrid}
                                           className={"mgr5"}
                                           style={{textTransform: 'uppercase'}}
                                           size={"large"}
                                           onClick={this.onClose}/>
                            <ButtonGeneral name={Config.lang('Duyet')}
                                           color={"primary"}
                                           variant={"contained"}
                                           loading={isSaving || loadingDataGrid}
                                           className={"mgr5"}
                                           icon={<Icons className={"fas fa-check"} />}
                                           style={{textTransform: 'uppercase'}}
                                           size={"large"}
                                           onClick={() => this.onApproval(0)}/>
                            <ButtonGeneral name={Config.lang('Tu_choi')}
                                           color={"danger"}
                                           variant={"contained"}
                                           disabled={isSaving || loadingDataGrid}
                                           icon={<Icons className={"fas fa-times"} />}
                                           style={{textTransform: 'uppercase'}}
                                           onClick={() => this.onApproval(1)}/>
                        </ActionToolbar>
                    </Modal.Title>
                    <Divider />
                    <Modal.Content>
                        <Row>
                            <Col xs={12} sm={4} md={4} lg={4} className={classes.panel + " " + (minimum && window.innerWidth > 768 ? classes.leftPanelMinimum : "")}>
                                <Row>
                                    <ActionToolbar
                                        alignment={"space-between"}
                                        // title={Config.lang("Loc_chon_nhan_vien")}
                                        showBorder={false}
                                        upperCase={false}
                                        className={minimum ? classes.titleColorFilter : ""}
                                        style={{
                                            marginTop: 0,
                                            backgroundColor: '#F7F9FC',
                                            padding: '0 15px',
                                            marginBottom: 15,
                                            fontSize: '1rem'
                                        }}
                                    >
                                        <div className={"display_row align-center valign-middle"}>
                                            <IconButton color="inherit" aria-label="menu" onClick={() => this.collapsePanel()}>
                                                <InlineSVG src={require('../../../../assets/images/filter.svg')} />
                                            </IconButton>
                                            {!minimum && <Typography variant="h6" className={"align-center"}>
                                                {Config.lang("Loc_chon_nhan_vien")}
                                            </Typography>}
                                        </div>
                                        {!minimum && <IconButton size={"small"} color="inherit" aria-label="menu" onClick={() => this.collapsePanel()}>
                                            <ChevronLeftIcon fontSize={"large"} />
                                        </IconButton>}
                                    </ActionToolbar>
                                </Row>
                                <div className={classes.panelForm + " form-field " + (minimum ? "hide" : "")}>
                                    <FormGroup style={{marginBottom: 5}}>
                                        <div className={"display_row align-center align-between"} style={{marginLeft: -10}}>
                                            <Checkbox
                                                checked={isShowSelected}
                                                label={Config.lang("Hien_thi_du_lieu_da_chon")}
                                                color={'primary'}
                                                onChange={this.changeTypeShow}
                                            />
                                            {/*<Typography>*/}
                                            {/*    {this.getTotalSelectedRowKey()}*/}
                                            {/*</Typography>*/}
                                        </div>
                                    </FormGroup>
                                    <FormGroup style={{marginBottom: 5}}>
                                        <Row>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <label className={"mgb0 text-uppercase"}>{Config.lang('Nhan_vien')}</label>
                                                <TextField
                                                    placeholder={Config.lang('Ma_nhan_vien_Ten_nhan_vien')}
                                                    variant={"outlined"}
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                    defaultValue={strSearch}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <SearchIcon />
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                    onChange={(e) => this.handleChange('Search', e)}
                                                    fullWidth
                                                />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup style={{marginBottom: 5}}>
                                        <Row>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <label className={"mgb0 text-uppercase"}>{Config.lang('Co_cau_to_chuc')}</label>
                                                <DropDownOrganization
                                                    placeholder={Config.lang("Co_cau_to_chuc")}
                                                    showClearButton={true}
                                                    selectionMode={'multiple'}
                                                    typeDefault={"organizational"}
                                                    value={OrgChartID}
                                                    onValueChanged={(e, data) =>
                                                        this.handleChange("OrgChartID", data)
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup style={{marginBottom: 5}}>
                                        <Row>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <label className={"mgb0 text-uppercase"}>{Config.lang("Ngay_tang_ca")}</label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} sm={6} md={6} lg={6}>
                                                <div className={"display_row align-center"}>
                                                    <label className={"mgb0 mgr5 text-uppercase"}>{Config.lang("Tu")}</label>
                                                    <DateBoxPicker
                                                        max={AttendanceDateTo}
                                                        placeholder={"dd/mm/yyyy"}
                                                        useMaskBehavior={true}
                                                        showClearButton={true}
                                                        width={"100%"}
                                                        onValueChanged={e => this.handleChange("AttendanceDateFrom", e)}
                                                    />
                                                </div>
                                            </Col>
                                            <Col xs={12} sm={6} md={6} lg={6}>
                                                <div className={"display_row align-center"}>
                                                    <label className={"mgb0 mgr5 text-uppercase"}>{Config.lang("Den")}</label>
                                                    <DateBoxPicker
                                                        min={AttendanceDateFrom}
                                                        placeholder={"dd/mm/yyyy"}
                                                        useMaskBehavior={true}
                                                        showClearButton={true}
                                                        width={"100%"}
                                                        onValueChanged={e => this.handleChange("AttendanceDateTo", e)}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup className={"display_row align_center valign-bottom"}>
                                        <ButtonGeneral name={Config.lang('Tim_kiem')}
                                                       typeButton={'search'}
                                                       color={"primary"}
                                                       className={"pdl10 pdr10"}
                                                       variant={"outlined"}
                                                       style={{textTransform: 'uppercase'}}
                                                       size={"large"}
                                                       disabled={isSaving || loadingDataGrid}
                                                       onClick={this.onFilter}/>
                                    </FormGroup>
                                </div>
                            </Col>
                            <Col xs={12} sm={8} md={8} lg={8} className={classes.panel + " " + (window.innerWidth > 768 ? "bdl " + (minimum ? classes.rightPanelMinimum : "") : "")}>
                                <FormGroup>
                                    <GridContainer
                                        reference={ref => this.dataGrid = ref}
                                        totalItems={totalDataGrid}
                                        itemPerPage={this.filter.limit}
                                        skipPerPage={this.filter.skip}
                                        listPerPage={[15, 30, 45, 60]}
                                        dataSource={_dataGrid}
                                        disabled={isSaving}
                                        keyExpr={'TransID'}
                                        gridProps={{
                                            style: {
                                                minHeight: 400,
                                            }
                                        }}
                                        pagerFullScreen={false}
                                        showBorders={false}
                                        columnAutoWidth={true}
                                        typeShort={window.innerWidth < 768}
                                        loadPanel={{
                                            enabled: loading
                                        }}
                                        height={"calc(100vh - 180px)"}
                                        selection={{
                                            allowSelectAll: true,
                                            mode: "multiple",
                                            selectAllMode: "allPages",
                                            showCheckBoxesMode: "always"
                                        }}
                                        showColumnLines={true}
                                        allowColumnResizing={true}
                                        selectedRowKey={_selectedRowKeys}
                                        onChangePage={this.onChangePage}
                                        onChangePerPage={this.onChangePerPage}
                                        onSelectionChanged={this.onSelectionChanged}
                                    >
                                        <Column
                                            caption={Config.lang("Ma_nhan_vien")}
                                            dataField={'EmployeeID'}
                                            alignment={"left"}
                                            width={150}
                                        />
                                        <Column
                                            caption={Config.lang("Ten_nhan_vien")}
                                            dataField={'EmployeeName'}
                                        />
                                        <Column
                                            caption={Config.lang("Ngay_tang_ca")}
                                            dataField={'AttendanceDate'}
                                            dataType={"date"}
                                            format={"dd/MM/yyyy"}
                                            // width={200}
                                        />
                                        <Column
                                            caption={Config.lang("Tang_ca_truoc")}
                                            alignment={"center"}
                                        >
                                            <Column
                                                caption={Config.lang("Tu")}
                                                dataField={'PreOTFrom'}
                                                width={100}
                                                alignment={"right"}
                                            />
                                            <Column
                                                caption={Config.lang("Den")}
                                                dataField={'PreOTTo'}
                                                width={100}
                                                alignment={"right"}
                                            />
                                            <Column
                                                caption={Config.lang("Gio")}
                                                dataField={'PreOTHours'}
                                                width={100}
                                                alignment={"right"}
                                            />

                                        </Column>
                                        <Column
                                            caption={Config.lang("Tang_ca_giua")}
                                            alignment={"center"}
                                            visible={!(dataGridSetting?.IsBetweenOT !== 1)}
                                        >
                                            <Column
                                                caption={Config.lang("Tu")}
                                                dataField={'BetweenOTFrom'}
                                                width={100}
                                                alignment={"right"}
                                            />
                                            <Column
                                                caption={Config.lang("Den")}
                                                dataField={'BetweenOTTo'}
                                                width={100}
                                                alignment={"right"}
                                            />
                                            <Column
                                                caption={Config.lang("Gio")}
                                                dataField={'BetweenOTHours'}
                                                width={100}
                                                alignment={"right"}
                                            />

                                        </Column>
                                        <Column
                                            caption={Config.lang("Tang_ca_sau")}
                                            alignment={"center"}
                                        >
                                            <Column
                                                caption={Config.lang("Tu")}
                                                dataField={'AfterOTFrom'}
                                                width={100}
                                                alignment={"right"}
                                            />
                                            <Column
                                                caption={Config.lang("Den")}
                                                dataField={'AfterOTTo'}
                                                width={100}
                                                alignment={"right"}
                                            />
                                            <Column
                                                caption={Config.lang("Gio")}
                                                dataField={'AfterOTHours'}
                                                width={100}
                                                alignment={"right"}
                                            />

                                        </Column>
                                        {dataUseOtType.IsUseOTType &&
                                            <Column
                                                caption={Config.lang("Loai_tach_tang_ca")}
                                                dataField={'OTTypeName'}
                                                // width={200}
                                            />
                                        }
                                        <Column
                                            caption={Config.lang("Loai_phieu")}
                                            dataField={'VoucherType'}
                                        />
                                        <Column
                                            visible={false}
                                            dataField={'ApprovalFlowID'}
                                            // width={200}
                                        />
                                        <Column
                                            visible={false}
                                            dataField={'ApprovalLevel'}
                                            // width={200}
                                        />
                                    </GridContainer>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Modal.Content>
                </Modal>
            </React.Fragment>
        )
    }
}

W29F2016.propTypes = {
    FormID: PropTypes.string,
    open: PropTypes.bool,

    /**
     * ProjectID: ""
     * DutyID: ""
     * ...
     * dataSelected: [{{EmployeeID: "", EmployeeName: "",...},{EmployeeID: "", EmployeeName: "",...}]
     * selectedRowKeys: [EmpID, EmpID]
     * isLoaded: false
     */
    dataDefaults: PropTypes.object,

    onClose: PropTypes.func,
    onChosen: PropTypes.func
};

export default compose(connect(null, (dispatch) => ({
    w29f2016Actions: bindActionCreators(W29F2016Actions, dispatch),
    generalActions: bindActionCreators(generalActions, dispatch),
})), withStyles(styles))(W29F2016);