/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 6/30/2020
 * @Example
 */
import React from "react";
import {bindActionCreators, compose} from "redux";
import PropTypes from 'prop-types';
import Config from "../../../../config";
import {connect} from "react-redux";
import * as w29f2011Actions from "../../../../redux/W2X/W29F2011/W29F2011_actions";
import {MForm} from "../../../common/form-material";
import {Button, Popover, TextInput} from "diginet-core-ui/components";

class W29F2011Popover extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            notes: "",
            errorNote: "",
            loading: false,
        };
    }

    onSave = async (numberStatus) => {
        const {data, funcLoadGrid, funcSaveApprovalStatus} = this.props;
        const {notes} = this.state;
        const validate = MForm.isRequired(notes, "errorNote");
        if (numberStatus === 100) {
            if (validate && validate.errorNote) {
                this.setState(validate);
                return false;
            }
        }
        const params = {
            TransID: data.TransID,
            DivisionID: Config.getDivisionID(),
            HostID: "",
            Language: Config.language || '84',
            EmployeeID: data.EmployeeID,
            ApprovalLevel: data.ApprovalLevel,
            ApprovalFlowID: data.ApprovalFlowID,
            ApprovalStatus: String(numberStatus),
            ApprovalsNotes: notes,
            Mode: numberStatus === 100 ? 0 : 1
        };
        this.setState({loading: true});
        funcSaveApprovalStatus(true);
        await this.props.w29f2011Actions.approvalStatus(params, (errors, data) => {
            this.setState({loading: false});
            funcSaveApprovalStatus(false);
            if (errors) {
                Config.popup.show('ERROR', errors);
                return false;

            }
            if (data) {
                if (data.Status === 1) {
                    const msg = data.Message || Config.lang("Loi_chua_xac_dinh");
                    Config.popup.show('INFO', msg);
                    return false;
                } else {
                    const msg = numberStatus === 1 ? Config.lang("Duyet_thanh_cong") : Config.lang("Tu_choi_thanh_cong");
                    Config.notify.show("success", msg, 3000);
                    funcLoadGrid && funcLoadGrid();
                    this.setState({
                        notes: "", errorNote: ""
                    });
                }
            }
        });

        if (this.popover) this.popover.close();
    };

    onCancel = (numberStatus) => {
        const {data, funcLoadGrid, funcSaveApprovalStatus} = this.props;
        const {notes} = this.state;
        const validate = MForm.isRequired(notes, "errorNote");
        if (validate && validate.errorNote) {
            this.setState(validate);
            return false;
        }
        const params = {
            TransID: data.TransID,
            DivisionID: Config.getDivisionID(),
            HostID: "",
            Language: Config.language || '84',
            EmployeeID: data.EmployeeID,
            ApprovalLevel: data.ApprovalLevel,
            ApprovalFlowID: data.ApprovalFlowID,
            ApprovalStatus: String(numberStatus),
            ApprovalsNotes: notes
        };
        Config.popup.show("YES_NO", Config.lang("Ban_co_chac_chan_bo_duyet"), () => {
            this.setState({loading: true});
            funcSaveApprovalStatus(true);
            this.props.w29f2011Actions.cancelApproval(params, error => {
                this.setState({loading: false});
                funcSaveApprovalStatus(false);
                if (error) {
                    Config.popup.show("ERROR", error);
                    return false;
                } else {
                    Config.notify.show("success", Config.lang("Bo_duyet_thanh_cong"), 3000);
                    funcLoadGrid && funcLoadGrid();
                    this.setState({
                        notes: "", errorNote: ""
                    });
                }
            });
        });
        if (this.popover) this.popover.close();
    };

    onNoteChange = (e) => {
        this.setState({
            notes: e.target.value
        })
    };

    onCheck = () => {
        const {anchorEl} = this.props;
        if(this.popover) {
            this.popover.setPosition(anchorEl);
            this.popover.show();
        }
    };

    onClose = () => {
        if (this.popover) this.popover.close();
    };

    render() {
        const {type, data, onClose} = this.props;
        const {notes, errorNote, loading} = this.state;
        return (
            <React.Fragment>
                <Popover
                    ref={ref => this.popover = ref}
                    anchorOrigin={{
                    vertical : "center",
                    horizontal : "left"
                    }}
                    transformOrigin={{
                    vertical: "center",
                    horizontal: "right"
                    }}
                    arrow
                    width={Config.isMobile ? 500 : 590}
                    onClose={onClose}
                >
                    <div style={{padding: "37px 28px"}}>
                        <TextInput
                            label={Config.lang("Ghi_chu_duyet")}
                            multiline={true}
                            maxRows={3}
                            value={notes}
                            error={!notes && errorNote ? errorNote : ""}
                            onChange={this.onNoteChange}
                        />
                        {
                            data ?
                                <div className={"display_row valign-bottom"}>
                                    {type === "check"
                                        ?
                                        <Button
                                            text={Config.lang("Duyet")}
                                            loading={loading}
                                            viewType={"filled"}
                                            color={"primary"}
                                            size={"large"}
                                            className={"mgr5"}
                                            onClick={() => this.onSave(1)}
                                        />
                                        : type !== "cancel" ?
                                            <Button
                                                text={Config.lang("Tu_choi")}
                                                loading={loading}
                                                viewType={"filled"}
                                                color={"danger"}
                                                size={"large"}
                                                className={"mgr5"}
                                                onClick={() => this.onSave(100)}
                                            />
                                            : ""
                                    }
                                    {Number(data.AppStatusID) !== 0 ?
                                    <Button
                                        text={Config.lang("Bo_duyet")}
                                        loading={loading}
                                        viewType={"filled"}
                                        color={"primary"}
                                        size={"large"}
                                        onClick={() => this.onCancel(0)}
                                    />
                                    : ""}
                                </div>
                                : ""
                        }
                    </div>
                </Popover>
            </React.Fragment>
        );
    }

}

W29F2011Popover.propTypes = {
    className: PropTypes.string,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    anchorEl: PropTypes.any,
    type: PropTypes.string,
    data: PropTypes.any,
    funcSaveApprovalStatus: PropTypes.func,
    funcLoadGrid: PropTypes.func,
};

export default compose(connect((state) => ({
        approvalStatus: state.W29F2011.approvalStatus
    }),
    (dispatch) => ({
        w29f2011Actions: bindActionCreators(w29f2011Actions, dispatch),
    }), null, {forwardRef: true}
))(W29F2011Popover);