/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 6/30/2020
 * @Example
 */
import React, { Component } from "react";

import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";

import withStyles from "@material-ui/core/styles/withStyles";
import { Row, Col, FormGroup } from "react-bootstrap";
import { Column } from "devextreme-react/data-grid";
import _ from "lodash";

import * as generalActions from "../../../../redux/general/general_actions";
import * as w29f2011Actions from "../../../../redux/W2X/W29F2011/W29F2011_actions";
import GridContainer from "../../../grid-container/grid-container";
import Config from "../../../../config";
import Filter from "../../../filter/filter";
import { Combo } from "../../../common/form-material";
import DateBoxPicker from "../../../common/form-material/date-box";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import ButtonGeneral from "../../../common/button/button-general";
import UserName from "../../../common/user/user-name";
import Chip from "@material-ui/core/Chip";
import Modal from "../../../common/modal/modal";
import W29F2012 from "../../W29/W29F2012/W29F2012";
import W29F2011Popover from "./W29F2011Popover";
import GridActionBar from "../../../grid-container/grid-actionbar";
import W84F3005 from "../../../W8X/W84/W84F3005/W84F3005";
import W29F2016 from "../../../W2X/W29/W29F2016/W29F2016";
import W29F2018 from "../W29F2018/W29F2018";
import { ButtonIcon, Avatar } from "diginet-core-ui/components";

const styles = {
    divAvatar: {
        width: 32,
        height: 32,
        borderRadius: "50%",
        overflow: "hidden",
        marginRight: 8,
    },
    imgAvatar: {
        maxWidth: "100%",
        width: "100%",
        maxHeight: "100%",
        height: "100%",
        objectFit: "cover",
    },
    dateInfo: {
        display: "flex",
        alignItems: "center",
    },
    divText: {
        // fontSize: "14px",
        fontWeight: 500,
        // color: "#575757"
    },
    divDateText: {
        fontSize: "14px",
        fontWeight: 400,
        color: "#000000",
    },
    chip: {
        fontSize: "1rem",
        fontWeight: 400,
        border: "none",
        backgroundColor: "#EBEBEB",
    },
    iconHoverRed: {
        "&:hover": {
            color: "#FF405A",
        },
    },
    iconHoverGreen: {
        "&:hover": {
            color: "#1CA261",
        },
    },
    itemAtt: {
        borderRadius: 100,
        padding: "0 8px",
        border: "1px solid lightgrey",
        fontWeight: "400",
        marginRight: 10,
        fontSize: 12,
        height: 24,
        lineHeight: "22px",
        display: "block",
        marginBottom: 10,
        "&:hover": {
            backgroundColor: "#E4E9F2",
        },
    },
    slick: {
        backgroundColor: "rgba(0, 0, 0, 0.08)",
        position: "absolute",
        top: "50%",
        zIndex: 2,
    },
    columnActions: {
        minHeight: "58px !important",
    },
};

const FormID = "W29F2011";
class W29F2011 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            iPermission: { W09F2016: 0, [FormID]: 0 },
            gridLoading: false,
            DateFrom: null,
            DateTo: null,
            dataCboEmployees: {
                rows: [],
                total: 0,
            },
            loadingCboEmployees: false,
            open: false,
            anchorEl: null,
            type: "",
            showW29F2012: false,
            dataPopup: null,
            dataPopover: null,
            showW84F3005Popup: false,
            showW29F2016Popup: false,
            w84F3005PopupData: {
                TransID: null,
            },
        };
        this.loading = {
            cboProjects: false,
        };
        this.filter = {
            fieldSort: "",
            typeSort: "",
            dateFrom: null,
            dateTo: null,
            AppStatusID: "",
            DepartmentID: "",
            Employee: null,
            skip: 0,
            limit: 20,
            isDisabledW29F2018: false,
            isEditW29F2018: false,
            isSavedApprovalStatus: false,
        };
        this.timer = null;
        this.filterCboEmployees = {
            strSearch: "",
            skip: 0,
            limit: 50,
        };
        this.refPopover = null;
        this.isRenderModalW29F2016 = false;
        this.W29F2018Ref = null;
    }

    loadPermission = async () => {
        await this.props.generalActions.getPermission(["W29F2011", "W29F2016"], isPer => {
            this.setState({ iPermission: Object.fromEntries(isPer.map(item => [item.FormID, item.IsPermission])) });
        });
    };

    setLoading = (key, status = false) => {
        if (!key) return;
        this.setState({ ["loading" + key]: status });
    };

    loadDataGrid = VoucherID => {
        const { voucher_id } = Config.getUrlParams(this.props);
        VoucherID = VoucherID ? VoucherID : voucher_id;
        const { DateFrom, DateTo } = this.state;
        const { skip, limit, AppStatusID, DepartmentID, Employee, fieldSort, typeSort } = this.filter;
        const params = {
            TransID: VoucherID || "",
            FormID: FormID,
            Language: Config.language || 84,
            HostID: "",
            DateFrom: DateFrom,
            DateTo: DateTo,
            AppStatusID: AppStatusID,
            DepartmentID: DepartmentID,
            EmployeeID: Employee ? Employee.EmployeeID : "",
            VoucherDate: "",
            Mode: 0,
            skip: skip,
            limit: limit,
            FieldSort: fieldSort,
            TypeSort: typeSort,
        };
        this.setState({ gridLoading: true });
        this.props.w29f2011Actions.getDataGrid(params, (error, data) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            this.setState({ gridLoading: false });
        });
    };

    loadCboAppStatus = () => {
        const params = {
            Language: Config.language || "84",
            FormID: "W75F2005",
        };

        this.setState({ gridLoading: true });
        this.props.generalActions.getCboAppStatus(params, error => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            this.setState({ gridLoading: false });
        });
    };

    loadCboDepartments = () => {
        const params = {
            DivisionID: Config.getDivisionID(),
            CompanyID: "",
            HostID: "",
            FormID: FormID,
            Language: Config.language || "84",
        };

        this.setState({ gridLoading: true });
        this.props.generalActions.getCboDepartments(params, error => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            this.setState({ gridLoading: false });
        });
    };

    loadGetOTTypeName = () => {
        this.setState({ gridLoading: true });
        this.props.w29f2011Actions.getOTTypeName({}, error => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            this.setState({ gridLoading: false });
        });
    };

    loadCboEmployees = isReset => {
        const { skip, limit, strSearch } = this.filterCboEmployees;
        const params = {
            HostID: "",
            FormID: FormID,
            Language: Config.language || "84",
            Mode: 0,
            skip: skip,
            limit: limit,
            search: strSearch,
        };

        this.setLoading({ CboEmployees: true });
        this.setState({ loading: this.loading });
        this.props.generalActions.getCboEmployees(params, (error, data) => {
            this.setLoading({ CboEmployees: false });
            this.setState({ loading: this.loading });
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                const { dataCboEmployees } = this.state;
                const rows = data && data.rows ? data.rows : data;
                const total = data && data.total ? data.total : data.length;
                this.setState({
                    dataCboEmployees: {
                        rows: isReset ? rows : dataCboEmployees.rows.concat(rows),
                        total: total,
                    },
                });
            }
        });
    };

    loadGridSetting = () => {
        this.props.w29f2011Actions.getGridSetting(errors => {
            if (errors) {
                Config.popup.show("ERROR", errors);
                return false;
            }
        });
    };

    componentDidMount = async () => {
        await this.loadPermission();
        if (!this.state.iPermission[FormID]) return;
        this.loadCboAppStatus();
        this.loadCboDepartments();
        this.loadCboEmployees();
        this.loadGetOTTypeName();
        this.loadDataGrid();
        this.loadGridSetting();
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { getDataGrid } = this.props;
        Config.callChildForm(
            {
                FormID: FormID,
                // ID: voucher_id,
                keyExpr: "TransID",
                data: getDataGrid && getDataGrid.rows ? getDataGrid.rows : [],
                onLoad: params => this.loadDataGrid(params && params.ID),
                onAction: (e, data) => this.onDbCellClick({ row: { data: data } }),
            },
            this.props
        );
    }

    filterChange(key, data) {
        if (!key) return false;
        const value = _.get(data, "value", "");
        switch (key) {
            case "DateFrom":
                this.setState({ DateFrom: value });
                break;
            case "DateTo":
                this.setState({ DateTo: value });
                break;
            case "DepartmentID":
                this.filter.DepartmentID = value;
                break;
            case "EmployeeID":
                this.filter.Employee = value;
                break;
            case "AppStatusID":
                this.filter.AppStatusID = value;
                break;

            default:
                break;
        }
    }

    onHistoryApproval = (e, data) => {
        this.setState({ w84F3005PopupData: { TransID: data ? data.TransID : null }, showW84F3005Popup: true });
    };
    onSearch = () => {
        this.loadDataGrid();
    };

    onChangePage = page => {
        this.filter.skip = page * this.filter.limit;
        this.loadDataGrid();
    };

    onChangePerPage = perPage => {
        this.filter.skip = 0;
        this.filter.limit = perPage;
        this.loadDataGrid();
    };

    renderAvatar = e => {
        let data = Config.getUser({ EmployeeID: e.data.EmployeeID });
        return (
            <div className={"display_row align-center"}>
                <Avatar
                    src={Config.getCDNPath(data.UserPictureURL)}
                    data={Config.getInfoAvatar(data)}
                    hoverAble
                    width={32}
                    height={32}
                />
            </div>
        );
    };

    renderAction = e => {
        const { classes } = this.props;
        const { isSavedApprovalStatus, dataPopover } = this.state;
        const { data } = e.row;
        const appStatusID = Number(_.get(data, "AppStatusID", 10));
        const arrBtnAction = _.filter(
            [
                {
                    classesName: "",
                    onAction: this.onHistoryApproval,
                    iconName: "History",
                    isVisible: appStatusID === 0 || appStatusID === 2 || appStatusID === 4,
                    loading: false,
                },
                {
                    classesName: classes.iconHoverGreen,
                    onAction: this.onOpenCheck,
                    iconName: "Approval",
                    isVisible: appStatusID === 0,
                    loading: data?.TransID === dataPopover?.TransID && isSavedApprovalStatus,
                },
                {
                    classesName: classes.iconHoverRed,
                    onAction: this.onOpenClose,
                    iconName: "ApprovalCancel",
                    isVisible: appStatusID === 0,
                    loading: data?.TransID === dataPopover?.TransID && isSavedApprovalStatus,
                },
                {
                    classesName: "",
                    onAction: this.onOpenCancel,
                    iconName: "Cancel",
                    isVisible: appStatusID === 2 || appStatusID === 4,
                    loading: data?.TransID === dataPopover?.TransID && isSavedApprovalStatus,
                },
            ],
            item => Boolean(item.isVisible)
        );
        return (
            <GridActionBar>
                <div className={"display_row"}>
                    {_.map(arrBtnAction, (item, i) => (
                        <React.Fragment key={i}>
                            <ButtonIcon
                                loading={item.loading}
                                name={item.iconName}
                                size={"medium"}
                                viewType={"text"}
                                circular={true}
                                onClick={e => item.onAction(e, data)}
                            />
                        </React.Fragment>
                    ))}
                    {/*<IconButton aria-label="view"*/}
                    {/*            size="small"*/}
                    {/*            className={classes.iconHoverGreen}*/}
                    {/*            onClick={(e) => this.onOpenCheck(e, data)}>*/}
                    {/*    <Icon className="fas fa-check" fontSize={"small"}/>*/}
                    {/*</IconButton>*/}
                    {/*<Divider*/}
                    {/*    orientation="vertical" style={{width: 2, height: 11, margin: '0 5px'}}*/}
                    {/*/>*/}
                    {/*<IconButton aria-label="view"*/}
                    {/*            size="small"*/}
                    {/*            className={classes.iconHoverRed}*/}
                    {/*            onClick={(e) => this.onOpenClose(e, data)}>*/}
                    {/*    <Icon className="fas fa-times" fontSize={"small"}/>*/}
                    {/*</IconButton>*/}
                    {/* {data && Number(data.AppStatusID) === 2 && Number(data.IsNotApproval) === 1 &&*/}
                    {/*     <div className={"display_row align-center valign-middle"}>*/}
                    {/*         <IconButton aria-label="view" size="small" onClick={() => this.onHistoryApproval(e, data)}>*/}
                    {/*             <HistoryIcon />*/}
                    {/*         </IconButton>*/}
                    {/*         <Divider*/}
                    {/*             orientation="vertical" style={{width: 2, height: 11, margin: '0 5px'}}*/}
                    {/*         />*/}
                    {/*         <IconButton aria-label="view" size="small" onClick={(e) => this.onOpenCancel(e, data)}>*/}
                    {/*             <InlineSVG src={require('../../../../assets/images/general/cancel.svg')}/>*/}
                    {/*         </IconButton>*/}
                    {/*     </div>*/}
                    {/*}*/}
                </div>
            </GridActionBar>
        );
    };

    renderStatus = e => {
        const { classes } = this.props;
        const { data } = e.row;
        const color = Number(data.AppStatusID) === 0 ? "black" : Number(data.AppStatusID) === 4 ? "red" : "green";
        return (
            <Chip label={data.AppStatusName} className={classes.chip} style={{ color: color }} variant={"outlined"} />
        );
    };
    renderName = e => {
        const { classes } = this.props;
        const { data } = e.row;
        return (
            <div className={"display_row align-center pdt10 pdb10"}>
                <div className={"w75f2000_info"}>
                    <div className={classes.divText}>
                        <UserName data={data} />
                    </div>
                </div>
            </div>
        );
    };

    onOpenClose = (e, data) => {
        this.setState(
            {
                open: true,
                anchorEl: e.currentTarget,
                type: "close",
                dataPopover: data,
            },
            () => {
                if (this.refPopover) {
                    this.refPopover.onCheck();
                }
            }
        );
    };

    onOpenCheck = (e, data) => {
        this.setState(
            {
                open: true,
                anchorEl: e.currentTarget,
                type: "check",
                dataPopover: data,
            },
            () => {
                if (this.refPopover) {
                    this.refPopover.onCheck();
                }
            }
        );
    };

    onOpenCancel = (e, data) => {
        this.setState(
            {
                open: true,
                anchorEl: e.currentTarget,
                type: "cancel",
                dataPopover: data,
            },
            () => {
                if (this.refPopover) {
                    this.refPopover.onCheck();
                }
            }
        );
    };

    onClose = () => {
        this.setState({
            open: false,
        });
    };

    onCloseModal = () => {
        this.setState({
            showW29F2012: false,
        });
    };

    showPopupW29F2016 = () => {
        this.setState({
            showW29F2016Popup: true,
        });
        this.isRenderModalW29F2016 = true;
    };

    onClosePopupW29f2016 = (isLoaded = false) => {
        this.setState(
            {
                showW29F2016Popup: false,
            },
            () => {
                if (isLoaded) {
                    this.loadDataGrid();
                }
            }
        );
    };

    renderFilter = () => {
        const { getCboAppStatus, getCboDepartments, classes } = this.props;
        const { Employee, DepartmentID, AppStatusID } = this.filter;
        const { loadingCboEmployees, dataCboEmployees, DateFrom, DateTo } = this.state;

        return (
            <Filter
                showClearButton={true}
                placeholder={Config.lang("Nhap_tu_tim_kiem")}
                openOnFieldClick={true}
                renderFilter={() => {
                    return (
                        <>
                            <FormGroup style={{ marginBottom: "7px" }}>
                                <Row>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <div className={classes.divText}>{Config.lang("Tu")}</div>
                                            <DateBoxPicker
                                                dateBoxProps={{ max: DateTo }}
                                                style={{ marginLeft: "5px" }}
                                                onValueChanged={e => this.filterChange("DateFrom", e)}
                                                useMaskBehavior={true}
                                                showClearButton={true}
                                                placeholder={"DD/MM/YYYY"}
                                                value={DateFrom || null}
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <div className={classes.divText}>{Config.lang("Den").toLowerCase()}</div>
                                            <DateBoxPicker
                                                dateBoxProps={{ min: DateFrom }}
                                                style={{ marginLeft: "5px" }}
                                                onValueChanged={e => this.filterChange("DateTo", e)}
                                                useMaskBehavior={true}
                                                showClearButton={true}
                                                placeholder={"DD/MM/YYYY"}
                                                value={DateTo || null}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup style={{ marginBottom: "7px" }}>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Combo
                                            label={Config.lang("Trang_thai_duyet")}
                                            dataSource={getCboAppStatus}
                                            displayExpr={"AppStatusName"}
                                            valueExpr={"AppStatusID"}
                                            onValueChanged={e => this.filterChange("AppStatusID", e)}
                                            showClearButton={true}
                                            value={AppStatusID}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup style={{ marginBottom: "7px" }}>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Combo
                                            label={Config.lang("Phong_ban")}
                                            dataSource={Config.storeDataSoureDevExtreme(getCboDepartments)}
                                            displayExpr={"DepartmentName"}
                                            valueExpr={"DepartmentID"}
                                            onValueChanged={e => this.filterChange("DepartmentID", e)}
                                            showClearButton={true}
                                            value={DepartmentID}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup style={{ marginBottom: "7px" }}>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Combo.LoadMore
                                            dataSource={dataCboEmployees}
                                            skip={this.filterCboEmployees.skip}
                                            limit={this.filterCboEmployees.limit}
                                            displayExpr={"EmployeeName"}
                                            keyExpr={"EmployeeID"}
                                            valueExpr={"EmployeeID"}
                                            value={Employee}
                                            stylingMode={"outlined"}
                                            loading={loadingCboEmployees}
                                            label={Config.lang("Nhan_vien")}
                                            itemRender={e => {
                                                const { data } = e;
                                                if (!data) return null;
                                                return data.EmployeeID + " - " + data.EmployeeName;
                                            }}
                                            showClearButton={true}
                                            // showCheckBoxMode={"none"}
                                            onValueChanged={e => this.filterChange("EmployeeID", e)}
                                            onInputChanged={e => {
                                                this.filterCboEmployees.strSearch = e.target.value;
                                                this.filterCboEmployees.skip = 0;
                                                this.loadCboEmployees(true);
                                            }}
                                            onLoadMore={e => {
                                                this.filterCboEmployees.skip = e.skip;
                                                this.filterCboEmployees.limit = e.limit;
                                                this.loadCboEmployees();
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    paddingTop: "38px",
                                    paddingBottom: "40px",
                                }}
                            >
                                <ButtonGeneral
                                    // disabled={!(iPermission >= 1)}
                                    name={Config.lang("Tim_kiem")}
                                    typeButton={"search"}
                                    color={"primary"}
                                    className={"pdl10 pdr10"}
                                    variant={"outlined"}
                                    style={{ textTransform: "uppercase" }}
                                    size={"large"}
                                    onClick={this.onSearch}
                                />
                            </div>
                        </>
                    );
                }}
            />
        );
    };

    onOrderChanged = e => {
        if (!e || !e.column) return false;
        this.filter.fieldSort = e.column.dataField;
        this.filter.typeSort = e.typeSort;
        this.loadDataGrid();
    };

    onDbCellClick = e => {
        const { data } = e.row;
        if (data?.IsCancel) {
            this.setState(
                {
                    isDisabledW29F2018: true,
                    isEditW29F2018: true,
                },
                () => {
                    if (this.W29F2018Ref) {
                        const currentTarget = e?.event?.currentTarget ?? e?.event?.target ?? null;
                        this.W29F2018Ref.onOpen(currentTarget, data);
                    }
                }
            );
        } else {
            this.setState({
                showW29F2012: true,
                dataPopup: data,
            });
        }
    };

    onCloseW29F2018 = (isReload = false) => {
        this.setState(
            {
                isDisabledW29F2018: false,
                isEditW29F2018: false,
            },
            () => {
                isReload && this.loadDataGrid();
            }
        );
    };

    saveApprovalStatus = (isSaved = false) => {
        this.setState({ isSavedApprovalStatus: isSaved });
    };

    render() {
        const {
            gridLoading,
            showW29F2012,
            dataPopup,
            anchorEl,
            type,
            dataPopover,
            open,
            showW84F3005Popup,
            w84F3005PopupData = {},
            showW29F2016Popup,
            iPermission,
            isDisabledW29F2018,
            isEditW29F2018,
        } = this.state;
        if (iPermission[FormID] <= 0) return null;
        const { skip, limit } = this.filter;
        const { classes, getDataGrid, getOTTypeName, getGridSetting } = this.props;

        const dataRows = _.get(getDataGrid, "rows", []);
        const totalRow = _.get(getDataGrid, "total", 0);
        return (
            <>
                <W84F3005
                    open={showW84F3005Popup}
                    onClose={e => this.setState({ showW84F3005Popup: false })}
                    FormID={FormID}
                    TransID={w84F3005PopupData.TransID}
                />
                {this.isRenderModalW29F2016 && (
                    <W29F2016
                        open={showW29F2016Popup}
                        onClose={isLoaded => this.onClosePopupW29f2016(isLoaded)}
                        FormID={FormID}
                    />
                )}
                <Modal
                    open={showW29F2012}
                    maxWidth={"md"}
                    fullWidth={true}
                    onClose={this.onCloseModal}
                    component={
                        <W29F2012
                            formID={FormID}
                            onClose={this.onCloseModal}
                            funcLoadGrid={this.loadDataGrid}
                            mode={"view"}
                            data={dataPopup}
                        />
                    }
                />
                <W29F2018
                    ref={ref => (this.W29F2018Ref = ref)}
                    position={"top"}
                    isDisabled={isDisabledW29F2018}
                    isEdit={isEditW29F2018}
                    onClose={isReload => this.onCloseW29F2018(isReload)}
                />
                {open && (
                    <W29F2011Popover
                        ref={ref => (this.refPopover = ref)}
                        open={open}
                        onClose={this.onClose}
                        anchorEl={anchorEl}
                        type={type}
                        data={dataPopover}
                        funcSaveApprovalStatus={this.saveApprovalStatus}
                        funcLoadGrid={this.loadDataGrid}
                    />
                )}
                <FormGroup>
                    <ActionToolbar title={Config.lang("Duyet_tang_ca")}>
                        <ButtonGeneral
                            name={Config.lang("Duyet_hang_loat")}
                            disabled={iPermission["W29F2016"] <= 1}
                            color={"primary"}
                            variant={"contained"}
                            className={"mgr5"}
                            style={{ textTransform: "uppercase" }}
                            size={"large"}
                            onClick={this.showPopupW29F2016}
                        />
                    </ActionToolbar>
                    <div className={"hidden"}>{this.renderFilter()}</div>
                    <FormGroup>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} className="gird-w29f2005">
                                <GridContainer
                                    itemPerPage={limit}
                                    skipPerPage={skip}
                                    loading={gridLoading}
                                    style={{ border: "none" }}
                                    dataSource={dataRows}
                                    keyExpr={"TransID"}
                                    showBorders={false}
                                    typePaging={"remote"}
                                    totalItems={totalRow}
                                    selection={{
                                        mode: "single",
                                    }}
                                    onDbCellClick={e => this.onDbCellClick(e)}
                                    onOrderChanged={this.onOrderChanged}
                                    height={Config.getHeightGrid()}
                                    typeShort={window.innerWidth < 768}
                                    showColumnLines={true}
                                    hoverStateEnabled={true}
                                    onChangePage={this.onChangePage}
                                    onChangePerPage={this.onChangePerPage}
                                >
                                    <Column cellRender={this.renderAvatar} />
                                    <Column
                                        style={{ backgroundColor: "#b3bbc1" }}
                                        caption={Config.lang("Ma_NV")}
                                        dataField={"EmployeeID"}
                                        alignment={"left"}
                                    />
                                    <Column
                                        caption={Config.lang("Ten_nhan_vien")}
                                        dataField={"EmployeeName"}
                                        cellRender={this.renderName}
                                    />
                                    <Column
                                        style={{ backgroundColor: "#b3bbc1" }}
                                        caption={Config.lang("Ngay_tang_ca")}
                                        dataField={"AttendanceDate"}
                                        alignment={"center"}
                                        dataType={"date"}
                                        format={"dd/MM/yyyy"}
                                    />
                                    <Column caption={Config.lang("Tang_ca_truoc")} alignment={"center"}>
                                        <Column
                                            caption={Config.lang("Tu")}
                                            dataField={"PreOTFrom"}
                                            alignment={"right"}
                                        />
                                        <Column
                                            caption={Config.lang("Den")}
                                            dataField={"PreOTTo"}
                                            alignment={"right"}
                                        />
                                        <Column
                                            caption={Config.lang("Gio")}
                                            dataField={"PreOTHours"}
                                            alignment={"right"}
                                        />
                                    </Column>
                                    <Column
                                        caption={Config.lang("Tang_ca_giua")}
                                        alignment={"center"}
                                        visible={!(getGridSetting?.IsBetweenOT !== 1)}
                                    >
                                        <Column
                                            caption={Config.lang("Tu")}
                                            dataField={"BetweenOTFrom"}
                                            alignment={"right"}
                                        />
                                        <Column
                                            caption={Config.lang("Den")}
                                            dataField={"BetweenOTTo"}
                                            alignment={"right"}
                                        />
                                        <Column
                                            caption={Config.lang("Gio")}
                                            dataField={"BetweenOTHours"}
                                            alignment={"right"}
                                        />
                                    </Column>
                                    <Column caption={Config.lang("Tang_ca_sau")} alignment={"center"}>
                                        <Column
                                            caption={Config.lang("Tu")}
                                            dataField={"AfterOTFrom"}
                                            alignment={"right"}
                                        />
                                        <Column
                                            caption={Config.lang("Den")}
                                            dataField={"AfterOTTo"}
                                            alignment={"right"}
                                        />
                                        <Column
                                            caption={Config.lang("Gio")}
                                            dataField={"AfterOTHours"}
                                            alignment={"right"}
                                        />
                                    </Column>
                                    {getOTTypeName.IsUseOTType === 1 && (
                                        <Column
                                            style={{ backgroundColor: "#b3bbc1" }}
                                            caption={Config.lang("Loai_tach_tang_ca")}
                                            dataField={"OTTypeName"}
                                        />
                                    )}
                                    <Column
                                        style={{ backgroundColor: "#b3bbc1" }}
                                        caption={Config.lang("Loai_ngay")}
                                        dataField={"WorkDayTypeName"}
                                        alignment={"center"}
                                    />
                                    <Column
                                        style={{ backgroundColor: "#b3bbc1" }}
                                        caption={Config.lang("Ca_vao_ra")}
                                        dataField={"ShiftInOut"}
                                        alignment={"center"}
                                    />
                                    <Column
                                        style={{ backgroundColor: "#b3bbc1" }}
                                        caption={Config.lang("Loai_phieu")}
                                        dataField={"VoucherType"}
                                        alignment={"center"}
                                    />
                                    <Column
                                        style={{ backgroundColor: "#b3bbc1" }}
                                        cssClass={classes.columnActions}
                                        caption={Config.lang("Trang_thai")}
                                        cellRender={this.renderStatus}
                                    />
                                    <Column
                                        width={150}
                                        alignment={"right"}
                                        fixed={true}
                                        fixedPosition={"right"}
                                        allowEditing={false}
                                        cellRender={this.renderAction}
                                    />
                                </GridContainer>
                            </Col>
                        </Row>
                    </FormGroup>
                </FormGroup>
            </>
        );
    }
}

export default compose(
    connect(
        state => ({
            getCboEmployees: state.general.getCboEmployees,
            getCboAppStatus: state.general.getCboAppStatus,
            getCboDepartments: state.general.getCboDepartments,
            getDataGrid: state.W29F2011.getDataGrid,
            getOTTypeName: state.W29F2011.getOTTypeName,
            getGridSetting: state.W29F2011.getGridSetting,
        }),
        dispatch => ({
            w29f2011Actions: bindActionCreators(w29f2011Actions, dispatch),
            generalActions: bindActionCreators(generalActions, dispatch),
        })
    ),
    withStyles(styles)
)(W29F2011);
