import React, { Component } from "react";
import { connect } from "react-redux";
import * as W29F2012Actions from "../../../../redux/W2X/W29F2012/W29F2012_actions";
import * as generalActions from "../../../../redux/general/general_actions";
import { bindActionCreators, compose } from "redux";
import Config from "../../../../config";
import { MForm } from "../../../common/form-material";
import Attachments from "../../../common/attachments/attachments";
import IconButton from "@material-ui/core/IconButton";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import Modal from "../../../common/modal/modal";
import _ from "lodash";
import Api from "../../../../services/api";
import CDN from "../../../CDN";
import InputMask from "react-input-mask";
import { TextField as TextFieldM } from "@material-ui/core";
import W29F2018 from "../W29F2018/W29F2018";
import {
    Button,
    TextInput,
    Popover,
    Dropdown,
    Avatar,
    Checkbox,
    Typography,
    HelperText,
    Row,
    Col,
    DatePicker,
    Label,
} from "diginet-core-ui/components";
import { useTheme } from "diginet-core-ui/theme";
const { spacing } = useTheme();

const arrTime = ["Pre", "Bet", "Aft"];

class W29F2012 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingCboEmployees: false,
            loadingCboOtType: false,
            loadingCheckTime: true,
            disabled: false,
            loading: false,
            uploading: false,
            dataForm: {
                TransID: "",
                employee: null,
                AttendanceDate: null,
                PreOTFrom: null,
                PreOTTo: null,
                PreOTHour: 0,
                BetOTFrom: null,
                BetPreOTTo: null,
                BetPreOTHour: 0,
                AftOTFrom: null,
                AftOTTo: null,
                AftOTHour: 0,
                ShiftName: "",
                ShiftID: "",
                Reason: "",
                OTTypeID: "",
                IsOverTime: "",
                WorkDayType: "",
                notes: "",
            },
            dataCboEmployees: {
                rows: [],
                total: 0,
            },
            isUseOTType: 0,
            dataOldAttachments: [],
            errorMask: {},
            errorMaskTwo: false,
            error: {},
            btnName: "",
            errorNote: "",
        };

        this.attachments = [];
        this.deletedFile = [];
        this.defaultParams = {
            FormID: "W29F2012",
            Language: Config.language || "84",
        };
        this.filterCboEmployees = {
            timer: null,
            search: "",
            skip: 0,
            limit: 20,
        };
        this.setCursor = {};
        this.setType = {};
        this.W29F2018Ref = null;
        this.refPopover = null;
        this.dataRequireFields = [
            { id: "Reason", nameField: Config.lang("Ly_do_tang_ca") },
            { id: "OTTypeID", nameField: Config.lang("Loai_tach_ca") },
            { id: "AttachmentID", nameField: Config.lang("Dinh_kem") },
        ];
    }

    static getDerivedStateFromProps = (nextProps, prevState) => {
        const currentUser = Config.getUser({ UserID: Config?.profile?.UserID });
        const employeeSelected = _.get(nextProps.getCboEmployees, "rows[0]", {});
        if (
            (employeeSelected.EmployeeID !== prevState.dataForm.EmployeeID ||
                Config?.profile?.UserID !== prevState.dataForm.EmployeeID) &&
            nextProps.mode === "add"
        ) {
            return { dataForm: { ...prevState.dataForm, employee: Config.menuType ? employeeSelected : currentUser } };
        } else {
            return null;
        }
    };

    createDateFromTextValue = value => {
        if (!value) return null;
        const splitParts = value.split(":");
        return new Date(1970, 1, 1, splitParts[0], splitParts[1]);
    };

    loadCaption = () => {
        this.setState({ loading: true });
        this.props.w29f2012Actions.getCaption(err => {
            this.setState({ loading: false });
            if (err) Config.popup.show("ERROR", err);
        });
    };

    getOTHour = key => {
        const { dataForm } = this.state;
        const { AttendanceDate, employee, ShiftID = "" } = dataForm;
        const params = {
            AttendanceDate: Config.convertDate(AttendanceDate, "", "YYYY-MM-DD"),
            OTFrom: dataForm[`${key}OTFrom`] || dataForm[`Sh${key}OTFrom`] || "",
            OTTo: dataForm[`${key}OTTo`] || dataForm[`Sh${key}OTTo`] || "",
            EmployeeID: employee?.EmployeeID || "",
            ShiftID,
        };

        this.props.w29f2012Actions.checkOTHour(params, (err, data) => {
            if (err || data.Status === 1) Config.popup.show("ERROR", err || data.Message);
            else this.setState({ dataForm: { ...dataForm, [`${key}OTHour`]: data.OTHour } });
        });
    };

    loadForm = () => {
        const { Language } = this.defaultParams;
        const { data: dataProp } = this.props;
        const params = {
            TransID: _.get(this.props.data, "TransID", ""),
            Language,
        };
        this.setState({ loading: true });
        this.props.w29f2012Actions.getForm(params, (err, data) => {
            if (err) Config.popup.show("ERROR", err);
            if (data) {
                let getUser = Config.getUser({ EmployeeID: _.get(data, "EmployeeID", "") });

                let dataTime = {};
                arrTime.forEach(keyTime => {
                    const keyFrom = `${keyTime}OTFrom`;
                    const keyTo = `${keyTime}OTTo`;
                    const keyHour = `${keyTime}OTHour`;
                    dataTime[keyFrom] = data?.[keyFrom] || dataProp?.[keyFrom] || "";
                    dataTime[keyTo] = data?.[keyTo] || dataProp?.[keyTo] || "";
                    dataTime[keyHour] = data?.[keyHour] ?? dataProp?.[keyHour] ?? 0;
                });

                const attendanceDate = _.get(data, "AttendanceDate", null);
                const propAttendanceDate = _.get(this.props.data, "AttendanceDate", null);
                const oTTypeID = _.get(data, "OTTypeID", false);
                this.setState({
                    loadingCheckTime: _.isNil(attendanceDate) || _.isNil(propAttendanceDate),
                    dataForm: {
                        ..._.omit(data, ["AppStatusID"]),
                        ...dataTime,
                        employee: _.pick(getUser ? getUser : dataProp, [
                            "EmployeeID",
                            "EmployeeName",
                            "UserPictureURL",
                        ]),
                        OTTypeID: oTTypeID ? oTTypeID : _.get(dataProp, "OTTypeID", ""),
                        AttendanceDate: attendanceDate ? attendanceDate : propAttendanceDate,
                    },
                });
            }
            this.setState({ loading: false });
        });
    };

    loadCboOtType = (param = {}) => {
        const { mode, getForm } = this.props;
        const { dataForm } = this.state;
        const { Language } = this.defaultParams;

        // const dataTime = {};
        let employeeID = "";
        let attendanceDate = null;

        if (mode === "edit" || mode === "view") {
            // arrTime.forEach(keyTime => {
            //     dataTime[`${keyTime}OTFrom`] = getForm?.[`${keyTime}OTFrom`] || null;
            //     dataTime[`${keyTime}OTTo`] = getForm?.[`${keyTime}OTTo`] || null;
            // });
            employeeID = _.get(getForm, "EmployeeID", "");
            attendanceDate = _.get(getForm, "AttendanceDate", null);
        } else if (mode === "add") {
            // arrTime.forEach(keyTime => {
            //     dataTime[`${keyTime}OTFrom`] = params?.[`${keyTime}OTFrom`] || null;
            //     dataTime[`${keyTime}OTTo`] = params?.[`${keyTime}OTTo`] || null;
            // });
            employeeID = _.get(dataForm, "employee.EmployeeID", "");
            attendanceDate = _.get(dataForm, "AttendanceDate", null);
        }

        const params = {
            // ...dataTime,
            Language,
            ShiftID: _.get(param, "ShiftID", ""),
            EmployeeID: employeeID,
            AttendanceDate: attendanceDate,
        };
        this.setState({ loadingCboOtType: true });
        this.props.w29f2012Actions.getCboOtType(params, err => {
            this.setState({ loadingCboOtType: false });
            if (err) Config.popup.show("ERROR", err);
        });
    };

    _getAttachments = () => {
        const listAttachments = Config.helpers.getFileInfomations(this.attachments);
        const { dataOldAttachments } = this.state;

        let arrAttachment = [];
        listAttachments.forEach(att => {
            arrAttachment.push({
                URL: att.url ? att.url : "",
                FileName: att.fileName ? att.fileName : "",
                FileSize: att.fileSize ? att.fileSize : "",
                FileExt: att.fileExt ? att.fileExt : "",
            });
        });
        if (dataOldAttachments && dataOldAttachments.length > 0) {
            arrAttachment = dataOldAttachments.concat(arrAttachment);
        }

        return arrAttachment;
    };

    loadRequiredFields = () => {
        this.setState({ loading: true });
        this.props.w29f2012Actions.getRequiredFields(error => {
            this.setState({ loading: false });
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    loadAttachments = () => {
        const { data } = this.props;
        const param = {
            KeyID: _.get(data, "TransID", ""),
        };
        this.setState({ loading: false });
        this.props.generalActions.getAttachmentsByTransID(param, (error, data) => {
            if (error) {
                Config.popup.show("ERROR", error);
            }
            if (data && data.length > 0) {
                this.setState({
                    dataOldAttachments: data,
                });
            }
            this.setState({ loading: false });
        });
    };

    onUploading = value => {
        this.setState({ uploading: value });
    };

    _removeCDN = () => {
        if (this.deletedFile && this.deletedFile.length > 0) {
            this.deletedFile.forEach(e => {
                const path = e.URL.split("=");
                if (path && path.length > 1) {
                    const params = {
                        path: path[1],
                    };
                    CDN.removeFile(params);
                }
            });
            this.deletedFile = [];
        }
    };

    onChangeAttachments = e => {
        this.attachments = e.uploadedFiles ? e.uploadedFiles : [];
        if (!_.isEmpty(this.attachments)) {
            this.setStateErrorText({
                error: {
                    ...this.state.error,
                    AttachmentID: "",
                },
            });
        }
        if (e.deletedFiles && e.deletedFiles.length > 0) {
            this.deletedFile = [...e.deletedFiles];
            const _arrRemove = this.deletedFile.map(d => d.AttachmentID);
            this.setState(prevState => ({
                dataOldAttachments: prevState.dataOldAttachments.filter(att => {
                    return _arrRemove.indexOf(att.AttachmentID) < 0;
                }),
            }));
        }
    };

    onAttachment = () => {
        if (this.attRef) {
            this.attRef.onAttachment();
        }
    };

    validationTime = () => {
        const { errorMask, dataForm } = this.state;
        let errTime = { ...errorMask };

        const params = {};
        arrTime.forEach((item, index) => {
            params[`${item}OTFrom`] = dataForm[`${item}OTFrom`];
            params[`${item}OTTo`] = dataForm[`${item}OTTo`];
            params[`${item}OTHour`] = dataForm[`${item}OTHour`];

            // Num01, Num02, Num03
            params[`Num0${index + 1}`] = dataForm[`${item}OTHour`];

            if (params[`${item}OTFrom`]?.includes("_") || params[`${item}OTTo`]?.includes("_")) {
                errTime[item] = true;
            } else {
                errTime[item] = false;
            }
        });
        if (!Object.keys(errTime).find(key => !!errTime[key])) {
            this.setStateErrorText(errTime, () => this.onStore(params));
        } else {
            this.onStore(params);
        }
    };

    onStore = async paramTime => {
        const { dataForm, dataOldAttachments } = this.state;
        let { data, mode, getRequiredFields, getCaption } = this.props;

        const arrAttachments = this._getAttachments();
        const listDataForm = Object.keys(dataForm);
        let params = {
            DivisionID: Config.getDivisionID(),
            TranMonth: Config.getHRTransMonth(),
            TranYear: Config.getHRTransYear(),
            TransID: _.get(data, "TransID", ""),
            EmployeeID: _.get(dataForm, "employee.EmployeeID", ""),
            ..._.omit(dataForm, ["employee"]),
            // OTFrom: dataForm.OTFrom,
            // OTTo: dataForm.OTTo,
            arrAttachment: JSON.stringify(arrAttachments),
            ...paramTime,
        };

        getCaption.forEach(item => {
            params[item.RefID] = +!!dataForm?.[item.RefID];
        });

        let fieldsRequire = [];
        let fieldsShowPopup = [];
        const filterAttachment =
            getRequiredFields && getRequiredFields.filter(item => item.SqlFieldName === "AttachmentID");

        getRequiredFields &&
            getRequiredFields.forEach(field => {
                if (
                    (field?.ValidMode === "O" &&
                        field.SqlFieldName &&
                        listDataForm.indexOf(field.SqlFieldName) > -1 &&
                        fieldsRequire.indexOf(field.SqlFieldName) < 0) ||
                    (filterAttachment[0].ValidMode === "O" &&
                    _.isEmpty(this.attachments) &&
                    _.isEmpty(dataOldAttachments)
                        ? field.SqlFieldName === "AttachmentID"
                        : "")
                ) {
                    fieldsRequire.push(field.SqlFieldName);
                }
                if (field?.ValidMode === "W" && field.SqlFieldName && fieldsShowPopup.indexOf(field.SqlFieldName) < 0) {
                    if (
                        listDataForm.indexOf(field.SqlFieldName) > -1 &&
                        _.isEmpty(this.state.dataForm[field.SqlFieldName]) &&
                        field.SqlFieldName !== "AttachmentID"
                    ) {
                        fieldsShowPopup.push(field.SqlFieldName);
                    }
                    if (field.SqlFieldName === "AttachmentID") {
                        if (!_.isEmpty(dataOldAttachments) || !_.isEmpty(this.attachments)) {
                            fieldsShowPopup = fieldsShowPopup.filter(f => f !== "AttachmentID");
                        } else {
                            fieldsShowPopup.push(field.SqlFieldName);
                        }
                    }
                }
            });
        //validate form..
        const validateRules = [];
        fieldsRequire &&
            fieldsRequire.forEach(item => {
                let rule;
                switch (item) {
                    case "Reason":
                        rule = {
                            name: "Reason",
                            rules: ["isRequired"],
                            value: dataForm?.Reason || "",
                        };
                        break;
                    case "OTTypeID":
                        rule = {
                            name: "OTTypeID",
                            rules: ["isRequired"],
                            value: dataForm?.OTTypeID || "",
                        };
                        break;
                    default:
                        rule = {
                            name: item,
                            rules: ["isRequired"],
                            value: dataForm[item] || "",
                        };
                        break;
                }
                validateRules.push(rule);
            });
        const validateForm = MForm.formValidation(validateRules);
        if (Object.keys(validateForm).length > 0) {
            this.setStateErrorText(validateForm);
            return false;
        } else {
            if (fieldsShowPopup?.length) {
                const fields = this.dataRequireFields.filter(i => fieldsShowPopup.indexOf(i.id) > -1);
                const arrFieldName = [];
                fields.forEach(i => arrFieldName.push(i.nameField));
                const nameField = arrFieldName?.length ? arrFieldName.join(", ") : "";
                const message = `${Config.lang("Ban_co_muon_bo_qua_truong")} ${nameField} ${Config.lang(
                    "Nay_khong"
                ).toLowerCase()}`;
                Config.popup.show("YES_NO", message, async () => {
                    await this._onStore(params, mode);
                });
            } else {
                await this._onStore(params, mode);
            }
        }
    };

    _onStore = async (params, mode) => {
        const { error } = this.state;
        let { onClose } = this.props;
        this.setState({ loading: true });
        const res = await Api.put(`/w29f2012/${mode}`, params);
        const isSuccess = !_.get(res, "data.Status", 1);
        const messageErr = _.get(res, "message", false);
        const checkErr = Object.values(error).every(err =>
            _.isObject(err) ? Object.values(err).every(x => !x) : !err
        );
        this.setState({ loading: false });
        if (messageErr) {
            Config.popup.show("ERROR", messageErr);
            return;
        }
        if (!checkErr) {
            return false;
        }

        if (isSuccess) {
            const mSuccess =
                mode === "add"
                    ? Config.lang("Dang_ky_tang_ca_thanh_cong")
                    : Config.lang("Chinh_sua_tang_ca_thanh_cong");
            Config.notify.show("success", mSuccess, 2000);
            this.setState({ disabled: true });
            this._removeCDN();
            onClose(true);
        } else {
            const {
                data: { MsgAsk = "", Message = "", code = "" },
            } = res;
            if (MsgAsk === 0) {
                Config.popup.show("INFO", Message);
            }
            if (MsgAsk === 1) {
                Config.popup.show("YES_NO", Message, () => {
                    params = { ...params, code: code };
                    this._saveData(params, mode);
                });
            }
        }
    };

    onApproval = numberStatus => {
        const { data, funcLoadGrid, onClose } = this.props;
        const { dataForm } = this.state;
        const validate = MForm.isRequired(dataForm.Notes, "errorNote");
        if (numberStatus === 100) {
            if (validate && validate.errorNote) {
                this.setState(validate);
                return false;
            }
        }

        const params = {
            TransID: data.TransID,
            DivisionID: Config.getDivisionID(),
            HostID: "",
            Language: Config.language || "84",
            EmployeeID: data.EmployeeID,
            ApprovalLevel: data.ApprovalLevel,
            ApprovalFlowID: data.ApprovalFlowID,
            ApprovalStatus: String(numberStatus),
            ApprovalsNotes: dataForm.Notes,
            Mode: numberStatus === 1 ? 1 : 0,
        };
        this.setState({ loading: true });
        this.props.w29f2012Actions.approvalStatus(params, (errors, data) => {
            this.setState({ loading: false });
            if (errors) {
                Config.popup.show("ERROR", errors);
                return false;
            }
            if (data) {
                if (data.Status === 1) {
                    const msg = data.Message || Config.lang("Loi_chua_xac_dinh");
                    Config.popup.show("INFO", msg);
                    return false;
                } else {
                    const msg =
                        numberStatus === 1 ? Config.lang("Duyet_thanh_cong") : Config.lang("Tu_choi_thanh_cong");
                    Config.notify.show("success", msg, 3000);
                    this.setState({
                        errorNote: "",
                        dataForm: {
                            ...this.state.dataForm,
                            Notes: "",
                        },
                    });
                    onClose && onClose();
                    funcLoadGrid && funcLoadGrid();
                }
            }
        });

        if (this.refPopover) this.refPopover.close();
    };

    onCancel = numberStatus => {
        const { data, funcLoadGrid, onClose } = this.props;
        const { dataForm } = this.state;
        const validate = MForm.isRequired(dataForm.Notes, "errorNote");
        if (validate && validate.errorNote) {
            this.setState(validate);
            return false;
        }
        const params = {
            TransID: data.TransID,
            DivisionID: Config.getDivisionID(),
            HostID: "",
            Language: Config.language || "84",
            EmployeeID: data.EmployeeID,
            ApprovalLevel: data.ApprovalLevel,
            ApprovalFlowID: data.ApprovalFlowID,
            ApprovalStatus: String(numberStatus),
            ApprovalsNotes: dataForm.Notes,
            Mode: 2,
        };
        this.setState({ loading: true });
        Config.popup.show("YES_NO", Config.lang("Ban_co_chac_chan_bo_duyet"), () => {
            this.setState({ loading: false });
            this.props.w29f2012Actions.cancelApproval(params, (error, dt) => {
                if (error) {
                    Config.popup.show("ERROR", error);
                    return false;
                } else if (dt) {
                    const { Status, Message } = dt;
                    if (Status === 0) {
                        Config.notify.show("success", Config.lang("Bo_duyet_thanh_cong"), 3000);
                        funcLoadGrid && funcLoadGrid();
                        this.setState({
                            errorNote: "",
                            dataForm: {
                                ...this.state.dataForm,
                                Notes: "",
                            },
                        });
                        onClose && onClose();
                        funcLoadGrid && funcLoadGrid();
                    } else {
                        Config.popup.show("INFO", Message);
                    }
                }
            });
        });
        if (this.refPopover) this.refPopover.close();
    };

    _saveData = async (params, mode) => {
        const { onClose } = this.props;
        this.setState({ loading: true });
        const res = await Api.put(`/w29f2012/confirm-${mode}`, params);
        const messages = _.get(res, "data.Message", Config.lang("Loi_chua_xac_dinh"));
        const isSuccess = !_.get(res, "data.Status", 1);
        const messageErr = _.get(res, "message", false);
        this.setState({ loading: false });
        if (messageErr) {
            Config.popup.show("ERROR", messageErr);
            return;
        }
        if (isSuccess) {
            const mSuccess =
                mode === "add"
                    ? Config.lang("Dang_ky_tang_ca_thanh_cong")
                    : Config.lang("Chinh_sua_tang_ca_thanh_cong");
            Config.notify.show("success", mSuccess, 2000);
            this.setState({ disabled: true });
            this._removeCDN();
            onClose(true);
        } else {
            Config.popup.show("INFO", messages);
        }
    };

    checkOTType = () => {
        Api.get("/w29f2012/check-use-ottype").then(res => {
            this.setState({ isUseOTType: _.get(res, "data.IsUseOTType", 0) });
        });
    };

    checkStore = async (typeCheck, param) => {
        const { dataForm } = this.state;
        const { Language } = this.defaultParams;
        const oTFrom = _.get(param, "OTFrom", false);
        const oTTo = _.get(param, "OTTo", false);
        const attendanceDate = _.get(param, "AttendanceDate", false);
        const shiftID = _.get(dataForm, "ShiftID", false);
        const employeeID = _.get(dataForm, "employee.EmployeeID", "");
        let params = {
            Language,
            EmployeeID: employeeID,
            ShiftID: shiftID ? shiftID : _.get(param, "ShiftID", ""),
            AttendanceDate: attendanceDate ? attendanceDate : _.get(dataForm, "AttendanceDate", null),
            OTFrom: oTFrom ? oTFrom : null,
            OTTo: oTTo ? oTTo : null,
        };

        const res = await Api.put(`/w29f2012/${typeCheck}`, params);
        const data = _.get(res, "data", {});
        const isValid = !_.get(data, "Status", 1);
        const message = _.get(data, "Message", Config.lang("Loi_chua_xac_dinh"));
        return { isValid, data, message };
    };

    setStateErrorText = (value, cb) => {
        this.setState(
            {
                error: {
                    ...value,
                },
            },
            cb
        );
        return Object.keys(value).length !== 0;
    };

    swap = (arr, x, y) => {
        let tmp = arr[x];
        arr[x] = arr[y];
        arr[y] = tmp;
        return arr;
    };

    getDataTime = (key, data) => {
        if (!key || !data) return false;
        let arr = data.split("");
        if (arr && arr.length > 0) {
            if (Number(arr[0]) >= 3) {
                arr = this.swap(arr, 0, 1);
                for (let i = 0; i < arr.length; i++) {
                    arr[i] = arr[i] === "_" ? "0" : arr[i];
                }
            } else {
                for (let i = 0; i < arr.length; i++) {
                    arr[i] = arr[i] === "_" ? "0" : arr[i];
                }
            }
        }

        if (key.includes("OT")) {
            return arr.join("");
        }
    };

    checkChangeTime = data => {
        let test = data.split("");
        return test.every((a, i) => {
            if (i === 0) {
                return !isNaN(a);
            }
            return isNaN(a);
        });
    };

    CheckDataWhenSelection = data => {
        let test = data.split("");
        return test.every((a, i) => {
            if (i === 2) {
                return typeof a === "string";
            }
            return !isNaN(a);
        });
    };

    moveCursor = (id, data) => {
        let check = false;
        if (data.charAt(0) <= 2 && data.charAt(1) <= 2 && this.CheckDataWhenSelection(data)) {
            check = true;
        }
        if (id.includes("OT")) {
            this.setCursor[id] = true;
        }
        let el = document.getElementById(`cursor${id}`);
        el.setSelectionRange(check ? 1 : 3, check ? 1 : 3);
    };

    checkOtFromEmpty = key => {
        const data = this.state.dataFrom?.[key];
        if (!data) return;
        if (data.includes("_")) {
            const arr = data.split("");
            const dataFilter = arr.filter(item => item === "_");
            if (dataFilter.length >= 4) {
                this.setCursor[key] = false;
                this.setType[key] = false;
            }
        }
    };

    checkOttoEmpty = key => {
        const data = this.state.dataFrom?.[key];
        if (!data) return;
        if (data.includes("_")) {
            const arr = data.split("");
            const dataFilter = arr.filter(item => item === "_");
            if (dataFilter.length >= 4) {
                this.setCursor[key] = false;
                this.setType[key] = false;
            }
        }
    };

    handleChange = async (key, e) => {
        const { dataForm } = this.state;
        const { getCaption } = this.props;
        const selection = window.getSelection();
        let value = _.get(e, "target.value", _.get(e, "value", e));
        let stateChange = { ...dataForm };

        if (getCaption?.find(i => i.RefID === key) || ["IsPreOT", "IsBetOT", "IsAftOT"].includes(key))
            value = e.target.checked;

        if (typeof value === "boolean") value = +!!value;

        switch (key) {
            case "IsPreOT":
            case "IsBetOT":
            case "IsAftOT": {
                const keyTime = key.slice(2, 5);

                if (value === 1) {
                    // Nếu <key>OTFrom = ‘’ thì gán Sh<key>OTFrom vào cột <keyTime>OTFrom (keyTime = [Pre, Bet, Aft])
                    if (!dataForm?.[`${keyTime}OTFrom`] || dataForm?.[`${keyTime}OTFrom`]?.includes("_")) {
                        stateChange = { ...stateChange, [`${keyTime}OTFrom`]: dataForm?.[`Sh${keyTime}OTFrom`] };
                    }
                    // Nếu <key>OTTo = ‘’ thì gán Sh<key>OTTo vào cột <key>OTTo (keyTime = [Pre, Bet, Aft])
                    if (!dataForm?.[`${keyTime}OTTo`] || dataForm?.[`${keyTime}OTTo`]?.includes("_")) {
                        stateChange = { ...stateChange, [`${keyTime}OTTo`]: dataForm?.[`Sh${keyTime}OTTo`] };
                    }
                } else {
                    stateChange = {
                        ...stateChange,
                        [`${keyTime}OTTo`]: "",
                        [`${keyTime}OTFrom`]: "",
                        [`${keyTime}OTHour`]: 0,
                    };
                }
                stateChange = { ...stateChange, [key]: value };

                break;
            }
            case "PreOTFrom":
            case "BetOTFrom":
            case "AftOTFrom":
            case "PreOTTo":
            case "BetOTTo":
            case "AftOTTo": {
                let checkData = value.split("");
                if (!checkData.some(val => val === "_")) {
                    this.setStateErrorText({ errorMask: { ...this.state.errorMask, [key]: false } });
                }

                if (value.charAt(0) > 2 && this.CheckDataWhenSelection(value)) {
                    return;
                }
                if (selection && !this.setType[key] && this.checkChangeTime(value)) {
                    if (selection.focusNode.childNodes[0].id === `cursor${key}`) {
                        stateChange = { ...dataForm, [key]: await this.getDataTime(key, value) };
                        this.setCursor[key] = false;
                    }
                } else {
                    stateChange = { ...dataForm, [key]: value };
                    this.setType[key] = true;
                }
                break;
            }
            case "EmployeeID": {
                stateChange = {
                    ...dataForm,
                    employee: { ...dataForm.employee, [key]: _.get(value, "EmployeeID", "") },
                };
                break;
            }
            case "AttendanceDate": {
                const result = await this.checkStore("check-shift", { AttendanceDate: value });
                this.setState({ loadingCheckTime: true });
                if (result.isValid) {
                    this.setState({ loadingCheckTime: false });
                    const dataStateTemp = {};
                    arrTime.forEach(keyTime => {
                        dataStateTemp[`${keyTime}OTFrom`] = null;
                        dataStateTemp[`${keyTime}OTTo`] = null;
                        dataStateTemp[`${keyTime}OTHour`] = 0;
                    });
                    stateChange = {
                        ...dataForm,
                        ...result.data,
                        ...dataStateTemp,
                        [key]: value,
                    };
                } else {
                    stateChange = { ...dataForm, [key]: dataForm?.AttendanceDate };
                    Config.popup.show("INFO", result.message);
                }
                break;
            }
            default:
                if (this.state.error[key] && !_.isEmpty(value)) {
                    this.setStateErrorText({
                        error: {
                            ...this.state.error,
                            [key]: "",
                        },
                    });
                }
                stateChange = {
                    ...dataForm,
                    [key]: value,
                };
                break;
        }

        //move focus input OT
        const arrInputTime = ["PreOTFrom", "BetOTFrom", "AftOTFrom", "PreOTTo", "BetOTTo", "AftOTTo"];
        if (
            arrInputTime.includes(key) &&
            !this.setCursor[key] &&
            stateChange?.[key] &&
            !stateChange[key].includes("_")
        ) {
            setTimeout(() => {
                this.moveCursor(key, stateChange[key]);
            }, 100);
        }

        this.setState({ dataForm: stateChange }, () => {
            arrTime.forEach(timeKey => {
                if (this.state.dataForm[`Is${timeKey}OT`]) {
                    this.checkOtFromEmpty(`${timeKey}OTFrom`);
                    this.checkOttoEmpty(`${timeKey}OTTo`);
                }
                if (["IsPreOT", "IsBetOT", "IsAftOT"].includes(key) && value) {
                    const keyTime = key.slice(2, 5);
                    this.getOTHour(keyTime);
                }
            });
        });
    };

    _checkMaskRules = data => {
        if (!data) return null;
        return data?.startsWith("2");
    };

    loadApiCheckTime = async (key, pos) => {
        const { dataForm } = this.state;
        const keyFrom = `${key}OTFrom`;
        const keyTo = `${key}OTTo`;
        if (!dataForm?.[keyFrom]?.includes("_") && !dataForm?.[keyTo]?.includes("_")) {
            let stateChange = {};
            this.setState({ loading: true });
            const params = {
                OTFrom: _.get(dataForm, keyFrom, null),
                OTTo: _.get(dataForm, keyTo, null),
            };
            const result = await this.checkStore("check-time", params);
            this.setState({ loading: false });
            if (result.isValid) {
                stateChange = {
                    ...dataForm,
                    // [key]: value,
                    [`${key}OTHour`]: _.get(result, "data.OTHour", 0),
                    IsOverTime: _.get(result, "data.IsOverTime", 0),
                };
                this.setCursor[`${key}OT${pos}`] = false;
                this.loadCboOtType({ ...params, ShiftID: _.get(dataForm, "ShiftID", "") });
                this.setState({ dataForm: stateChange });
            } else {
                this.setState({
                    dataForm: {
                        ...dataForm,
                        [`${key}OT${pos}`]: null,
                    },
                    error: {},
                });
                Config.popup.show("INFO", result.message);
            }
        }
    };

    onOpenW29F2018 = e => {
        if (this.W29F2018Ref) {
            const { data } = this.props;
            const currentTarget = e?.currentTarget ?? null;
            this.W29F2018Ref.onOpen(currentTarget, data);
        }
    };

    onMouseDown = e => {};

    render() {
        const {
            loadingCboEmployees,
            loadingCboOtType,
            loadingCheckTime,
            loading,
            uploading,
            disabled,
            dataForm,
            dataOldAttachments,
            isUseOTType,
            error,
            errorNote,
            btnName,
        } = this.state;
        const { data, formID, mode, getCboOtType, getCaption } = this.props;

        // check rules input time OT
        let checkRule = {};
        arrTime.forEach((item, idx) => {
            const keyFrom = `${item}OTFrom`;
            const keyTo = `${item}OTTo`;
            checkRule[keyFrom] = dataForm[keyFrom] && this._checkMaskRules(dataForm[keyFrom]);
            checkRule[keyTo] = dataForm[keyTo] && this._checkMaskRules(dataForm[keyTo]);
        });

        // check condition enable button save
        let allowSave = dataForm.AttendanceDate;
        if (allowSave) {
            arrTime.forEach(keyTime => {
                if (allowSave && dataForm[`Is${keyTime}OT`])
                    allowSave = dataForm[`${keyTime}OTFrom`] && dataForm[`${keyTime}OTTo`];
            });
        }

        const isView = mode === "view";
        const shiftName = _.get(dataForm, "ShiftName", "");
        const IsAllowCancel = data?.IsAllowCancel ?? "";
        const labelOT = new Map([
            ["Pre", Config.lang("Tang_ca_truoc")],
            ["Bet", Config.lang("Tang_ca_giua")],
            ["Aft", Config.lang("Tang_ca_sau")],
        ]);
        return (
            <>
                <W29F2018
                    ref={ref => (this.W29F2018Ref = ref)}
                    position={"top"}
                    onClose={isReload => this.props.onClose(isReload, true)}
                />
                <Popover
                    ref={ref => (this.refPopover = ref)}
                    anchorOrigin={{
                        vertical: "center",
                        horizontal: "left",
                    }}
                    transformOrigin={{
                        vertical: "center",
                        horizontal: "right",
                    }}
                    arrow
                    width={Config.isMobile ? 500 : 590}
                    onClose={() =>
                        this.setState({
                            errorNote: "",
                            dataForm: {
                                ...this.state.dataForm,
                                Notes: "",
                            },
                        })
                    }
                >
                    <div style={{ padding: "37px 28px" }}>
                        <TextInput
                            label={Config.lang("Ghi_chu_duyet")}
                            multiline={true}
                            maxRows={3}
                            value={dataForm.Notes}
                            error={!dataForm.Notes && errorNote ? errorNote : ""}
                            onChange={e => this.handleChange("Notes", e)}
                        />
                        <div className={"display_row valign-bottom"}>
                            {btnName === "Approval" ? (
                                <Button
                                    text={Config.lang("Duyet")}
                                    viewType={"filled"}
                                    color={"primary"}
                                    size={"large"}
                                    className={"mgr5"}
                                    disabled={loading}
                                    onClick={() => this.onApproval(1)}
                                />
                            ) : (
                                ""
                            )}
                            {btnName === "Refuse" ? (
                                <Button
                                    text={Config.lang("Tu_choi")}
                                    viewType={"filled"}
                                    color={"danger"}
                                    size={"large"}
                                    className={"mgr5"}
                                    disabled={loading}
                                    onClick={() => this.onApproval(100)}
                                />
                            ) : (
                                ""
                            )}
                            {Number(data?.AppStatusID) !== 0 ? (
                                <Button
                                    text={Config.lang("Bo_duyet")}
                                    viewType={"filled"}
                                    color={"primary"}
                                    size={"large"}
                                    disabled={loading}
                                    onClick={() => this.onCancel(0)}
                                />
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                </Popover>
                <Modal.Content>
                    <Row>
                        <Col xs={12} sm={6}>
                            <Dropdown
                                valueObjectDefault={dataForm?.employee && typeof dataForm?.employee === "object" ? dataForm.employee : null}
                                dataSource={dataForm?.employee ? [dataForm?.employee] : []}
                                displayExpr={"EmployeeName"}
                                valueExpr={"EmployeeID"}
                                value={dataForm?.employee?.EmployeeID}
                                disabled={true}
                                label={Config.lang("Nhan_vien")}
                                renderSelectedItem={data => {
                                    const { data: item } = data;
                                    return (
                                        <div className={"display_row align-center"}>
                                            <Avatar
                                                readOnly
                                                width={24}
                                                height={24}
                                                src={`${Config.getUserPicture(item?.UserPictureURL)}`}
                                            />
                                            <Typography
                                                hoverTooltip
                                                className={"mgl5"}
                                                lineClamp={1}
                                            >{`${item?.EmployeeID} - ${item?.EmployeeName}`}</Typography>
                                        </div>
                                    );
                                }}
                                inputProps={{
                                    readOnly: disabled,
                                }}
                                iconExpr={{
                                    key: "UserPictureURL",
                                    prefix: Config.getCDNPath(),
                                }}
                            />
                        </Col>
                        <Col sm={6} xs={12} />
                        <Col sm={6} xs={12}>
                            <DatePicker
                                displayFormat={"DD/MM/YYYY"}
                                label={Config.lang("Ngay")}
                                value={dataForm?.AttendanceDate}
                                onChange={e => this.handleChange("AttendanceDate", e)}
                                disabled={loading}
                                readOnly={isView}
                                required={true}
                            />
                        </Col>
                        <Col xs={12} sm={6} className={"display_row align-center"}>
                            <Typography className={"pdt6x"}>{shiftName}</Typography>
                        </Col>
                        <Col md={7} sm={9} xs={12}>
                            <Row>
                                {arrTime.map((keyTime, idx) => {
                                    const keyFrom = `${keyTime}OTFrom`;
                                    const keyTo = `${keyTime}OTTo`;
                                    return (
                                        <Col xs={12} key={idx}>
                                            <Row className={"display_row align-center"} columns={7}>
                                                <Col sm={2} xs={7}>
                                                    <Checkbox
                                                        disabled={
                                                            loading ||
                                                            dataForm?.[`Is${keyTime}OTD`] !== 1 ||
                                                            !dataForm?.AttendanceDate
                                                        }
                                                        label={labelOT.get(keyTime)}
                                                        readOnly={isView}
                                                        checked={dataForm?.[`Is${keyTime}OT`]}
                                                        onChange={e => this.handleChange(`Is${keyTime}OT`, e)}
                                                    />
                                                    <Typography style={{ marginLeft: spacing(7) }} type={"p3"}>
                                                        {dataForm?.[`Sh${keyTime}OTFrom`] || "00:00"} -{" "}
                                                        {dataForm?.[`Sh${keyTime}OTTo`] || "00:00"}
                                                    </Typography>
                                                </Col>
                                                <Col
                                                    sm={4}
                                                    xs={6}
                                                    style={{ position: "relative" }}
                                                    className={"display_row"}
                                                >
                                                    <div className={"display_col"}>
                                                        <Label>{Config.lang("Gio_tang_ca_tu")}</Label>
                                                        <InputMask
                                                            mask={"12:34"}
                                                            maskChar={loadingCheckTime || loading ? "" : "_"}
                                                            alwaysShowMask={true}
                                                            formatChars={{
                                                                1: "[0-9]",
                                                                2: checkRule[keyFrom] ? "[0-3]" : "[0-9]",
                                                                3: "[0-5]",
                                                                4: "[0-9]",
                                                            }}
                                                            onBlur={async () => {
                                                                if (dataForm[keyFrom]) {
                                                                    if (
                                                                        dataForm[keyFrom]
                                                                            .split("")
                                                                            .some(val => val === "_")
                                                                    ) {
                                                                        this.setStateErrorText({
                                                                            errorMask: {
                                                                                ...this.state.errorMask,
                                                                                [keyTime]: true,
                                                                            },
                                                                        });
                                                                    } else {
                                                                        this.setStateErrorText({
                                                                            errorMask: {
                                                                                ...this.state.errorMask,
                                                                                [keyTime]: false,
                                                                            },
                                                                        });
                                                                    }
                                                                }
                                                                if (!isView && dataForm?.[`Is${keyTime}OT`] === 1)
                                                                    await this.loadApiCheckTime(keyTime, "From");
                                                            }}
                                                            onKeyDown={e => {
                                                                if (
                                                                    e?.keyCode === 9 &&
                                                                    !isView &&
                                                                    dataForm?.[`Is${keyTime}OT`] === 1
                                                                ) {
                                                                    this.loadApiCheckTime(keyTime, "From");
                                                                }
                                                            }}
                                                            disabled={
                                                                loadingCheckTime ||
                                                                loading ||
                                                                dataForm?.[`Is${keyTime}OTD`] !== 1 ||
                                                                !dataForm.AttendanceDate
                                                            }
                                                            value={dataForm[keyFrom] || ""}
                                                            onChange={e => this.handleChange(keyFrom, e)}
                                                        >
                                                            {props => (
                                                                <TextFieldM
                                                                    {...props}
                                                                    InputLabelProps={{ shrink: true }}
                                                                    InputProps={{
                                                                        readOnly:
                                                                            isView ||
                                                                            dataForm?.[`Is${keyTime}OT`] !== 1,
                                                                    }}
                                                                    disabled={
                                                                        loadingCheckTime ||
                                                                        loading ||
                                                                        dataForm?.[`Is${keyTime}OTD`] !== 1 ||
                                                                        !dataForm?.AttendanceDate
                                                                    }
                                                                    required={dataForm?.[`Is${keyTime}OT`] !== 0}
                                                                    style={{ margin: `0 ${spacing(4)}px 0 0` }}
                                                                    inputProps={{
                                                                        id: `cursor${keyFrom}`,
                                                                        onMouseUp: () => {
                                                                            if (window.getSelection()) {
                                                                                this.setType[keyFrom] = false;
                                                                            }
                                                                        },
                                                                    }}
                                                                    variant={"standard"}
                                                                />
                                                            )}
                                                        </InputMask>
                                                    </div>
                                                    <div className={"display_col"}>
                                                        <Label>{Config.lang("Gio_tang_ca_den")}</Label>
                                                        <InputMask
                                                            mask={"12:34"}
                                                            maskChar={loadingCheckTime || loading ? "" : "_"}
                                                            alwaysShowMask={true}
                                                            formatChars={{
                                                                1: "[0-9]",
                                                                2: checkRule[keyTo] ? "[0-3]" : "[0-9]",
                                                                3: "[0-5]",
                                                                4: "[0-9]",
                                                            }}
                                                            disabled={
                                                                loadingCheckTime ||
                                                                loading ||
                                                                dataForm?.[`Is${keyTime}OTD`] !== 1 ||
                                                                !dataForm?.AttendanceDate
                                                            }
                                                            onBlur={() => {
                                                                if (dataForm[keyTo]) {
                                                                    if (
                                                                        dataForm[keyTo]
                                                                            .split("")
                                                                            .some(val => val === "_")
                                                                    ) {
                                                                        this.setStateErrorText({
                                                                            errorMask: {
                                                                                ...this.state.errorMask,
                                                                                [keyTime]: true,
                                                                            },
                                                                        });
                                                                    } else {
                                                                        this.setStateErrorText({
                                                                            errorMask: {
                                                                                ...this.state.errorMask,
                                                                                [keyTime]: false,
                                                                            },
                                                                        });
                                                                    }
                                                                }
                                                                if (!isView && dataForm?.[`Is${keyTime}OT`] === 1)
                                                                    this.loadApiCheckTime(keyTime, "To");
                                                            }}
                                                            onKeyDown={e => {
                                                                if (
                                                                    e?.keyCode === 9 &&
                                                                    !isView &&
                                                                    dataForm?.[`Is${keyTime}OT`] === 1
                                                                ) {
                                                                    this.loadApiCheckTime(keyTime, "To");
                                                                }
                                                            }}
                                                            value={dataForm[keyTo] || ""}
                                                            onChange={e => this.handleChange(keyTo, e)}
                                                        >
                                                            {props => (
                                                                <TextFieldM
                                                                    {...props}
                                                                    InputProps={{
                                                                        readOnly:
                                                                            isView ||
                                                                            dataForm?.[`Is${keyTime}OT`] !== 1,
                                                                    }}
                                                                    disabled={
                                                                        loadingCheckTime ||
                                                                        loading ||
                                                                        dataForm?.[`Is${keyTime}OTD`] !== 1 ||
                                                                        !dataForm?.AttendanceDate
                                                                    }
                                                                    InputLabelProps={{ shrink: true }}
                                                                    required={dataForm?.[`Is${keyTime}OT`] !== 0}
                                                                    style={{ margin: 0 }}
                                                                    inputProps={{
                                                                        id: `cursor${keyTo}`,
                                                                        onMouseUp: () => {
                                                                            if (window.getSelection()) {
                                                                                this.setType[keyTo] = false;
                                                                            }
                                                                        },
                                                                    }}
                                                                    variant={"standard"}
                                                                />
                                                            )}
                                                        </InputMask>
                                                    </div>
                                                    {error?.["errorMask"]?.[keyTime] && (
                                                        <HelperText style={{ position: "absolute", top: "100%" }}>
                                                            {Config.lang("Ban_phai_nhap_dung_dinh_dang_vd_11:11")}
                                                        </HelperText>
                                                    )}
                                                </Col>
                                                <Col sm={1} xs={1}>
                                                    <Typography color={"info"}>{`${
                                                        Math.round((dataForm?.[`${keyTime}OTHour`] ?? 0) * 100) / 100
                                                    } h`}</Typography>
                                                </Col>
                                            </Row>
                                        </Col>
                                    );
                                })}
                            </Row>
                        </Col>
                        <Col md={5} sm={3} xs={12}>
                            <Row className={"display_col align-start"}>
                                {getCaption.map((caption, index) => {
                                    if (!caption.Disabled) return null;
                                    return (
                                        <Col xs={12} key={index}>
                                            <Checkbox
                                                readOnly={isView}
                                                disabled={loading}
                                                key={index}
                                                label={caption?.RefCaption}
                                                checked={!!dataForm?.[caption.RefID]}
                                                onChange={e => this.handleChange(caption.RefID, e)}
                                            />
                                        </Col>
                                    );
                                })}
                            </Row>
                        </Col>
                        <Col sm={isUseOTType ? 6 : 12} xs={12}>
                            <TextInput
                                error={error && error["Reason"]}
                                label={Config.lang("Ly_do_tang_ca")}
                                value={dataForm.Reason || ""}
                                disabled={loading}
                                readOnly={isView}
                                onChange={e => this.handleChange("Reason", e)}
                            />
                        </Col>
                        {!!isUseOTType && (
                            <Col sm={6} xs={12}>
                                <Dropdown
                                    error={error && error["OTTypeID"]}
                                    readOnly={isView}
                                    dataSource={getCboOtType}
                                    displayExpr={"OTTypeName"}
                                    valueExpr={"OTTypeID"}
                                    disabled={loading || !dataForm.OTHour}
                                    loading={loadingCboOtType}
                                    allowSearch
                                    value={dataForm.OTTypeID}
                                    label={Config.lang("Loai_tach_ca")}
                                    onChange={e => this.handleChange("OTTypeID", e)}
                                />
                            </Col>
                        )}
                        <Col xs={12}>
                            <Attachments
                                error={error && error["AttachmentID"]}
                                ref={ref => (this.attRef = ref)}
                                showButton={true}
                                files={dataOldAttachments}
                                disabled={loading || uploading || isView}
                                maxLength={5}
                                uploading={loading}
                                onUploading={this.onUploading}
                                onChanged={this.onChangeAttachments}
                            />
                        </Col>
                    </Row>
                </Modal.Content>
                <Modal.Actions style={{ justifyContent: "space-between" }}>
                    <div>
                        <IconButton
                            aria-label="view"
                            disabled={loading || uploading || isView}
                            onClick={this.onAttachment}
                        >
                            <AttachFileIcon />
                        </IconButton>
                    </div>
                    <div style={{ display: "flex", gap: 10 }}>
                        {mode === "view" && !!IsAllowCancel && (
                            <Button
                                color={"info"}
                                size={"large"}
                                viewType={"filled"}
                                text={Config.lang("Huy_tang_ca")}
                                style={{ textTransform: "uppercase", margin: 0 }}
                                onClick={evt => this.onOpenW29F2018(evt)}
                            />
                        )}

                        {formID !== "W29F2011" && (
                            <Button
                                text={Config.lang("Luu")}
                                viewType={"filled"}
                                color={"info"}
                                size={"large"}
                                startIcon={"Save"}
                                disabled={
                                    !allowSave ||
                                    loading ||
                                    isView ||
                                    loadingCboOtType ||
                                    loadingCboEmployees ||
                                    disabled ||
                                    uploading
                                }
                                onClick={this.validationTime}
                            />
                        )}

                        {formID === "W29F2011" && Number(data?.AppStatusID) === 0 ? (
                            <>
                                <Button
                                    text={Config.lang("Tu_choi_W75")}
                                    viewType={"filled"}
                                    color={"info"}
                                    size={"large"}
                                    className={"mgr5"}
                                    disabled={loading}
                                    onClick={e => {
                                        if (this.refPopover) {
                                            this.refPopover.setPosition(e.currentTarget);
                                            this.refPopover.show();
                                        }
                                        this.setState({
                                            btnName: "Refuse",
                                            errorNote: "",
                                            dataForm: {
                                                ...this.state.dataForm,
                                                Notes: "",
                                            },
                                        });
                                    }}
                                />
                                <Button
                                    text={Config.lang("Duyet")}
                                    viewType={"filled"}
                                    color={"info"}
                                    size={"large"}
                                    disabled={loading}
                                    onClick={e => {
                                        if (this.refPopover) {
                                            this.refPopover.setPosition(e.currentTarget);
                                            this.refPopover.show();
                                        }
                                        this.setState({
                                            btnName: "Approval",
                                            errorNote: "",
                                            dataForm: {
                                                ...this.state.dataForm,
                                                Notes: "",
                                            },
                                        });
                                    }}
                                />
                            </>
                        ) : (
                            ""
                        )}
                        {formID === "W29F2011" &&
                        (Number(data?.AppStatusID) === 2 || Number(data?.AppStatusID) === 4) &&
                        Number(data?.IsNotApproval) === 1 &&
                        Number(data?.ApprovalStatusT4070 !== 5) ? (
                            <Button
                                text={Config.lang("Bo_duyet")}
                                viewType={"filled"}
                                color={"info"}
                                size={"large"}
                                disabled={loading}
                                onClick={e => {
                                    if (this.refPopover) {
                                        this.refPopover.setPosition(e.currentTarget);
                                        this.refPopover.show();
                                    }
                                    this.setState({
                                        btnName: "Cancel",
                                        errorNote: "",
                                        dataForm: {
                                            ...this.state.dataForm,
                                            Notes: "",
                                        },
                                    });
                                }}
                            />
                        ) : (
                            ""
                        )}
                    </div>
                </Modal.Actions>
            </>
        );
    }

    componentDidMount() {
        const { mode } = this.props;
        if (mode === "edit" || mode === "view") {
            this.loadForm();
            this.loadAttachments();
            this.loadCboOtType();
        }
        this.checkOTType();
        this.loadRequiredFields();
        this.loadCaption();
    }
}

export default compose(
    connect(
        state => ({
            getCboEmployees: state.general.getCboEmployees,
            getCboOtType: state.W29F2012.getCboOtType,
            getForm: state.W29F2012.getForm,
            getRequiredFields: state.W29F2012.getRequiredFields,
            getCaption: state.W29F2012.getCaption,
        }),
        dispatch => ({
            w29f2012Actions: bindActionCreators(W29F2012Actions, dispatch),
            generalActions: bindActionCreators(generalActions, dispatch),
        })
    )
)(W29F2012);
