/**
 * @Copyright 2021 @DigiNet
 * @Author XUANLOC
 * @Create 6/8/2021
 * @Example
 */

import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import PopoverAction from '../../../grid-container/popover-action';
import { Button, TextInput } from 'diginet-core-ui/components';
import { useStyles } from './styles';
import Config from '../../../../config';
import { useDispatch } from 'react-redux';
import * as W29F2018Actions from '../../../../redux/W2X/W29F2018/W29F2018_actions';
import moment from 'moment';

const W29F2018 = forwardRef((props, ref) => {
    const { position, isEdit, isDisabled, onClose } = props;
    const [data, setData] = useState({});
    const [cancelReason, setCancelReason] = useState('');
    const [error, setError] = useState({});
    const [isSaved, setIsSaved] = useState(false);

    const popoverRef = useRef(null);

    const onHiding = () => {
        setData({});
        setTimeout(() => {
            setCancelReason('');
        }, 100);
        if (onClose) onClose(isSaved);
        setIsSaved(false);
    };

    const onOpen = (target, data = {}) => {
        if (popoverRef.current && target) {
            setData(data);
            popoverRef.current.instance.show(target);
        }
    };

    useImperativeHandle(ref, () => ({
        onOpen,
    }));

    const classes = useStyles();

    const dispatch = useDispatch();

    const loadCancelReason = () => {
        const params = {
            TransID: isEdit ? data?.TransID ?? '' : '',
        };
        dispatch(
            W29F2018Actions.getCancelReason(params, (error, dt) => {
                if (error) {
                    const msg = error?.message ?? Config.lang('Loi_chua_xac_dinh');
                    Config.popup.show('error', msg);
                    return false;
                }
                if (dt) {
                    setCancelReason(dt?.CancelReason ?? '');
                }
            })
        );
    };

    useEffect(() => {
        if (Object.keys(data)?.length > 0) {
            loadCancelReason();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const onSave = () => {
        if (cancelReason?.length === 0) {
            setError({ ...error, CancelReason: Config.lang('Truong_nay_bat_buoc_nhap') });
            return false;
        }
        const params = {
            TransID: data?.TransID ?? '',
            FormID: 'W29F2010',
            Language: Config?.language ?? '84',
            EmployeeID: data?.EmployeeID ?? '',
            AttendanceDate: data?.AttendanceDate ? moment(data?.AttendanceDate, 'YYYY/MM/DD').format('YYYY-MM-DD') : null,
            CancelReason: cancelReason ?? '',
            Mode: +isEdit,
        };
        dispatch(
            W29F2018Actions.save(params, (error, data) => {
                if (error) {
                    const msg = error?.message ?? Config.lang('Loi_chua_xac_dinh');
                    Config.popup.show('error', msg);
                    return false;
                }
                if (data?.Status === 0) {
                    setIsSaved(true);
                    Config.notify.show('success', Config.lang('Luu_thanh_cong'), 2000);
                    if (popoverRef.current) {
                        popoverRef.current.instance.hide();
                    }
                } else if (data?.Status === 1) {
                    const msg = data?.Message ?? Config.lang('Da_xay_ra_loi,_khong_the_luu_du_lieu');
                    Config.popup.show('error', msg);
                }
            })
        );
    };

    const onHandleChange = (key, data) => {
        switch (key) {
            case 'CancelReason':
                const value = data?.target?.value ?? '';
                if (value?.length > 0) {
                    setError({ ...error, [key]: '' });
                }
                setCancelReason(value);
                break;
            default:
                break;
        }
    };

    return (
        <PopoverAction reference={popoverRef} position={position} deferRendering={false} shading={false} style={{ zIndex: 1300 }} onHiding={onHiding}>
            <div className={classes.popoverAction}>
                <TextInput
                    required
                    multiline
                    maxRows={3}
                    label={Config.lang('Ly_do_huy_dang_ky_tang_ca')}
                    value={cancelReason ?? ''}
                    onChange={(e) => onHandleChange('CancelReason', e)}
                    style={{ width: '100%' }}
                    error={error?.CancelReason ?? ''}
                />
                <Button disabled={isDisabled} color={'primary'} text={Config.lang('Luu')} onClick={onSave} style={{ textTransform: 'uppercase' }} />
            </div>
        </PopoverAction>
    );
});

W29F2018.propTypes = {
    position: PropTypes.string,
    isEdit: PropTypes.bool,
    isDisabled: PropTypes.bool,
    onClose: PropTypes.func,
};

W29F2018.defaultProps = {
    position: 'left',
    isEdit: false,
    isDisabled: false,
    onClose: null,
};

export default W29F2018;
