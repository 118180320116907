/**
 * @copyright 2021 @ DigiNet
 * @author Thanhhai
 * @create 07/01/2020
 * @Example
 */


import React from "react";
import {FormGroup, Col, Row, Image} from "react-bootstrap";
import Config from "../../../../config";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import Approvals from "../../../approvals/approvals";
import LabelText from "../../../common/label-text/label-text";
import {bindActionCreators, compose} from "redux";
import {connect} from "react-redux";
import {Input, withStyles, Typography, Box} from "@material-ui/core";
import InlineSVG from "react-inlinesvg";
import * as ApprovalActions from "../../../../redux/approvals/approvals_actions";
import moment from "moment";
import * as generalActions from "../../../../redux/general/general_actions";
import { Button } from "diginet-core-ui/components";
import W29F2017 from '../W29F2017/W29F2017';

const styles = {
    divAvatar: {
        width: 32,
        height: 32,
        borderRadius: '50%',
        border: '1px solid #ddd',
        overflow: 'hidden',
        marginRight: 8
    },
    imgAvatar: {
        maxWidth: '100%',
        maxHeight: '100%',
        objectFit: 'contain'
    },
    label: {
        fontSize: "1rem",
        fontWeight: 200,
        color:"#757575"
    },
    divText : {
        // fontSize: "1rem",
        fontWeight: 500,
        // color:"#575757"
    },
    shiftBox:{
        marginTop:15,
        display:"flex",
        alignItems:"center",
    },
    shifDayTypeColor:{
        width:32,
        height:32,
        marginLeft:10,
    },
    textUppercase: {
        textTransform: 'uppercase !important',
    },
};

class W29F2015 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            iPermission: {},
            dataForm: {},
            VoucherID: "",
            openW29F2017: false,
        };
        this.approvalRef = React.createRef();
    }

    loadPermission = async () => {
        await this.props.generalActions.getPermission(["W29F2015", "W29F2017"], (arrPer) => {
            if (arrPer) {
                let permission = {};
                arrPer.forEach(per => {
                    if (per.hasOwnProperty('FormID') && per.hasOwnProperty('IsPermission')) {
                        permission[per.FormID] = per.IsPermission;
                    }
                });
                this.setState({ iPermission: permission });
            }
        });
    };


    componentDidMount = async () => {
        await this.loadPermission();
        if (!this.state.iPermission) return;
    };

    getInfo = () => {
        const {location} = this.props;
        const url = new window.URLSearchParams(window.location.search);
        if (url && url.get('voucher_id')) {
            return {VoucherID: url.get('voucher_id')};
        } else if (location && location.state) {
            return {
                VoucherID: location.state.voucher_id,
            }
        } else {
            return {};
        }
    };

    onSelectionChanged = (e) => {
        if (!e) return false;
        this.loadFormInfo(e.VoucherID);
    };

    onDrawerClosed = () => {
        this.setState({VoucherID: ""});
    };

    loadFormInfo = (VoucherID, cb) => {
        const params = {
            DivisionID: Config.getDivisionID(),
            FormID: "W29F2015",
            Language: Config.language || "84",
            VoucherID: VoucherID ? VoucherID : "",
        };
        this.props.approvalActions.getFormInfo(params, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
            if (data) {
                data.employee =  Config.getUser({
                    EmployeeID:  data.EmployeeID,
                }) || {}
                this.setState({
                    dataForm: data
                });
                cb && cb (data);
            }
        });
    };
    renderFormInfo = (data) => {
        const {classes} = this.props;
        const {employee,ShiftName,DayTypeColor,AttendanceDate,InOutHour1,InOutHour2,InOutHour3,InOutHour4,InOutHour5,Note} = data||{}
        const InOutHour = [InOutHour1,InOutHour2,InOutHour3,InOutHour4,InOutHour5].filter(Boolean)
        const {UserPictureURL,EmployeeName} = employee||{}
        return (
            <>
                <FormGroup className={"mgb0"}>
                    <Row className={"mgb15 display_row align-center"}>
                        <Col xs={12} sm={12} md={6} lg={6} >
                            <LabelText
                                allowPadding={true}
                                label={Config.lang("DHR_Nhan_vien")}
                                value={() => {
                                    return (
                                        <div className={"display_row align-center pdb5"}
                                            style={{width: "100%", height: 37}}>
                                            <div className={classes.divAvatar}>
                                                {UserPictureURL ? (
                                                    <Image className={classes.imgAvatar} src={UserPictureURL}/>
                                                ) : (
                                                    <InlineSVG className={classes.imgAvatar}
                                                            src={require('../../../../assets/images/general/user_default.svg')}/>
                                                )}
                                            </div>
                                            <Input
                                                color={"primary"}
                                                readOnly={true}
                                                value={EmployeeName || ""}
                                                disableUnderline={true}
                                            />
                                        </div>
                                    );
                                }}
                                fullWidth={true}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} >
                            <Box className={classes.shiftBox}>
                                <Typography>{ShiftName}</Typography> 
                                <Box className={classes.shifDayTypeColor} style={{backgroundColor:DayTypeColor}}/>
                            </Box>
                            
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup className={"mgb0"}>
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6} className={"mgb15"}>
                        <LabelText
                            label={Config.lang("DHR_Ngay_tang_ca")}
                            allowPadding={true}
                            fullWidth={true}
                            value={AttendanceDate ? moment(AttendanceDate).format("DD/MM/YYYY") : ""}
                        /> 
                        </Col>
                    
                        {InOutHour.map((item,index)=>{
                            return  <Col xs={12} sm={12} md={6} lg={6} className={"mgb15"}>
                                        <LabelText
                                            key={index}
                                            label={`${Config.lang("DHR_Gio_vao_ra")} ${index+1}`}
                                            allowPadding={true}
                                            fullWidth={true}
                                            value={item}
                                        />
                            </Col>
                        })}
                    
                    </Row>
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <LabelText
                                allowPadding={true}
                                label={Config.lang("DHR_Ghi_chu")}
                                fullWidth={true}
                                value={Note || ""}
                            />
                        </Col>
                    </Row>
                </FormGroup>
                </>

        )
    };

    handleOpenW29F2017 = (flag = false) => {
        if (!flag && this.approvalRef.current) {
            this.approvalRef.current.loadGrid();
        }
        this.setState({ openW29F2017: flag });
    };

    render() {  
        const { classes } = this.props;
        const {iPermission, dataForm, VoucherID, openW29F2017} = this.state;
        if (!iPermission?.W29F2015) {
            return null
        }
        return (
            <React.Fragment>
                <FormGroup>
                    <ActionToolbar title={Config.lang("DHR_Duyet_gio_tang_ca_thuc_te")} >
                        <Button
                            className={`${classes.textUppercase}`}
                            color={'primary'} 
                            viewType={'filled'} 
                            text={Config.lang("Duyet_hang_loat")} 
                            disabled={(iPermission?.W29F2017 ?? -1) <= -1}
                            onClick={e => this.handleOpenW29F2017(true)}
                        />
                    </ActionToolbar>
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <Approvals
                                parentProps={this.props}
                                FormID={"W29F2015"}
                                dataForm={dataForm}
                                ref={this.approvalRef}
                                selectedRowKey={VoucherID}
                                singleClick
                                formInfoRender={this.renderFormInfo}
                                onSelectionChanged={this.onSelectionChanged}
                                onDrawerClosed={this.onDrawerClosed}
                                
                                isShowCboDepartment
                                isShowCboRegisterType={false}
                                isActionPermission={iPermission?.W29F2015 >= 2}
                                allowHistoryApproval
                            />
                        </Col>
                    </Row>
                </FormGroup>
                {openW29F2017 && <W29F2017 open={openW29F2017} handleOpenW29F2017={this.handleOpenW29F2017} />}
            </React.Fragment>
        );
    }

}

export default compose(connect((state) => ({
    
    }),
    (dispatch) => ({
        generalActions: bindActionCreators(generalActions, dispatch),
        approvalActions: bindActionCreators(ApprovalActions, dispatch),
    })
), withStyles(styles, {withTheme: true}))(W29F2015);
